import React, { FC } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { IconButton } from '@mui/material';
import { InfoCircleEmptyIcon } from '@utils/iconsDefs';

type Props = {
  text: string;
};

const InfoTooltip: FC<Props> = ({ text }) => {
  return (
    <CustomTooltip title={text}>
      <IconButton size="initial" sx={{ ml: 1, mb: 0.5 }}>
        <InfoCircleEmptyIcon width={18} height={18} />
      </IconButton>
    </CustomTooltip>
  );
};

export default InfoTooltip;
