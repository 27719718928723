import React, { FC, ReactNode } from 'react';
import { Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { InfoCircleEmptyIcon } from '@utils/iconsDefs';

type Props = SxProps & {
  children: ReactNode;
  required?: boolean;
  tooltip?: string;
};

const Label: FC<Props> = ({ children, required, tooltip, ...props }) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={2} mb={1}>
      <Typography variant="h5" sx={props}>
        {children}
        {required && (
          <span style={{ color: themePalette.system.error }}>*</span>
        )}
      </Typography>

      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoCircleEmptyIcon
            width={20}
            height={20}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default Label;
