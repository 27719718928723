import { OperationsTabs } from '@constants/entities/ui';
import {
  QmsRoles,
  RolesPermissionsOrElements,
  UserRoles,
} from '@constants/entities/user';

export const ROUTES = {
  MAIN: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  INVITATION_LOGIN: '/accept-invite',
  LOGIN_OAUTH: '/login_oauth',
  MAIN_STAGE: '/main-stage',
  INVALID_LINK: '/invalid_link',

  USER_PROFILE: '/me',
  DASHBOARD: '/dashboard',
  CONSULTANTS: '/consultants',
  CONSULTANT_PROFILE: '/consultants/:consultantId',
  SUPPORT: '/support',

  OPERATIONS: {
    MAIN: '/operations',
    TAB: `/operations/:tab`,
    QNOUS_REFERENCE: `/operations/${OperationsTabs.QNOUS_REFERENCE}`,
    SERVICE_TIERS: `/operations/${OperationsTabs.SERVICE_TIERS}`,
    SERVICE_TIER_DETAILS: `/operations/${OperationsTabs.SERVICE_TIERS}/:tierId`,
  },

  PROJECT: {
    LIST: '/projects',
    SCENARIO: {
      HOME: '/projects/:id/scenario',
      DASHBOARD: '/projects/:id/scenario/dashboard',
      MODEL: '/projects/:id/scenario/model',
      INSIGHTS: '/projects/:id/scenario/insights',
      RISKS: '/projects/:id/scenario/risks-log',
    },

    HOME: '/projects/:id',
    DASHBOARD: '/projects/:id/dashboard',
    MODEL: '/projects/:id/model',
    INSIGHTS: '/projects/:id/insights',
    SCENARIOS: '/projects/:id/scenarios',
    RISKS: '/projects/:id/risks-log',
    QUESTIONNAIRE: '/projects/:id/questionnaire',
    QUESTIONNAIRE_COMPLETED: '/projects/:id/questionnaire/completed',
  },
  PROJECT_SETTINGS: {
    MAIN: '/projects/:id/settings',
    MODES: '/projects/:id/settings/modes',
    USERS: '/projects/:id/settings/users',
    ENTERPRISE_SETTINGS: '/projects/:id/settings/enterprise-settings',
    PROJECT_SETTINGS: '/projects/:id/settings/project-settings',
    ACTIVITY_HISTORY: '/projects/:id/settings/activity-history',
  },
};

export const ROUTES_ALLOWED_ROLES = {
  DASHBOARD: [UserRoles.SuperAdmin],
  CONSULTANTS: [UserRoles.SuperAdmin],
  CONSULTANT_PROFILE: [UserRoles.SuperAdmin],
  OPERATIONS: {
    MAIN: [UserRoles.SuperAdmin],
  },
  PROJECT: {
    LIST: QmsRoles,
  },
  PROJECT_SETTINGS: {
    ENTERPRISE_SETTINGS:
      RolesPermissionsOrElements.EnterpriseAndProjectSettingsTabs,
    PROJECT_SETTINGS:
      RolesPermissionsOrElements.EnterpriseAndProjectSettingsTabs,
    ACTIVITY_HISTORY: RolesPermissionsOrElements.ProjectActivityHistory,
  },
};
