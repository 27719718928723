import React, { createElement, FC } from 'react';
import CiaInputsGroup from '@components/EntityDrawers/components/CiaInputsGroup';
import CurrentMaturity from '@components/EntityDrawers/components/inputs/CurrentMaturity';
import DesignEffectivenessDropdown from '@components/EntityDrawers/components/inputs/DesignEffectivenessDropdown';
import MetricsGroup from '@components/EntityDrawers/components/inputs/MetricsGroup';
import OperationalEffectivenessDropdown from '@components/EntityDrawers/components/inputs/OperationalEffectivenessDropdown';
import OperationalModelDropdown from '@components/EntityDrawers/components/inputs/OperationalModelDropdown';
import OperationalStatusRadioGroup from '@components/EntityDrawers/components/inputs/OperationalStatusRadioGroup';
import TargetMaturity from '@components/EntityDrawers/components/inputs/TargetMaturity';
import TimelineDates from '@components/EntityDrawers/components/inputs/TimelineDates';
import StandardsGroup from '@components/EntityDrawers/drawers/Physical/inputs/StandardsGroup';
import TechInstructionsGroup from '@components/EntityDrawers/drawers/Physical/inputs/TechInstructionsGroup';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { PhysicalSubLayerTypes } from '@constants/canvas/layers';

const SubLayerGroup = {
  [PhysicalSubLayerTypes.Functions]: TechInstructionsGroup,
  [PhysicalSubLayerTypes.Capabilities]: StandardsGroup,
};

const Advanced: FC = () => {
  const activeNode = useSourceNode();

  return (
    <>
      <OperationalStatusRadioGroup />
      <TimelineDates />
      <MetricsGroup />
      <CurrentMaturity />
      <TargetMaturity />

      <CiaInputsGroup />

      {createElement(
        SubLayerGroup[
          (activeNode?.parentNode as PhysicalSubLayerTypes) ??
            PhysicalSubLayerTypes.Capabilities
        ],
      )}

      <DesignEffectivenessDropdown />
      <OperationalEffectivenessDropdown />
      <OperationalModelDropdown />
    </>
  );
};

export default Advanced;
