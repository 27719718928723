import { useMemo } from 'react';
import { InsightKeys } from '@constants/entities/insights';
import useProjectData from '@hooks/useProjectData';

const useCiaData = () => {
  const [{ subscription, questionnaire }, { isLoading: isProjectLoading }] =
    useProjectData();

  const isCiaActivated = useMemo(() => {
    if (!subscription) return false;

    const isCiaInInsightsList = subscription.insights.includes(InsightKeys.CIA);

    return isCiaInInsightsList;
  }, [subscription]);

  return {
    isCiaActivated,
    questionnaire,
    isLoading: isProjectLoading,
  };
};

export default useCiaData;
