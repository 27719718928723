import { useCallback, useState } from 'react';
import useToggle from '@hooks/useToggle';

export type PromisedControls = {
  openModal: () => Promise<void>;
  closeModal: () => void;
  confirmAction: () => void;
};

const usePromisedConfirmModal = (): [boolean, PromisedControls] => {
  const [isModalOpen, { off, on }] = useToggle();

  const [resolveReject, setResolveReject] = useState<
    [(value: void | PromiseLike<void>) => void, (reason?: string) => void] | []
  >([]);

  const openModal = useCallback(async () => {
    return new Promise<void>((resolve, reject) => {
      setResolveReject([resolve, reject]);
      on();
    });
  }, [on]);

  const closeModal = useCallback(() => {
    resolveReject[1]?.('Action is not confirmed');
    off();
  }, [off, resolveReject]);

  const confirmAction = useCallback(() => {
    resolveReject[0]?.();
    off();
  }, [off, resolveReject]);

  return [isModalOpen, { openModal, closeModal, confirmAction }];
};

export default usePromisedConfirmModal;
