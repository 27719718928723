import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IScenario } from '@store/services/scenarios/types';
import { RootState } from '@store/store';

interface ProjectState {
  projectsTabs: Record<
    string,
    {
      currentId: string | null;
      scenarios: IScenario[];
    }
  >;
}

const initialState: ProjectState = {
  projectsTabs: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCreatedScenario: (
      state,
      {
        payload: { scenario, projectId },
      }: PayloadAction<{ scenario: IScenario; projectId: string }>,
    ) => {
      state.projectsTabs[projectId] = {
        currentId: scenario.id,
        scenarios: [
          scenario,
          ...(state.projectsTabs[projectId]?.scenarios ?? []),
        ],
      };
    },

    setCurrentScenario: (
      state,
      {
        payload: { scenarioId, projectId },
      }: PayloadAction<{ scenarioId: string; projectId: string }>,
    ) => {
      if (!state.projectsTabs[projectId]) {
        state.projectsTabs[projectId] = {
          currentId: null,
          scenarios: [],
        };
      }

      state.projectsTabs[projectId].currentId = scenarioId;
    },

    pushScenarioTabs: (
      state,
      {
        payload: { scenarios, projectId },
      }: PayloadAction<{ scenarios: IScenario[]; projectId: string }>,
    ) => {
      if (!state.projectsTabs[projectId]) {
        state.projectsTabs[projectId] = {
          currentId: null,
          scenarios: [],
        };
      }

      const commonTabsMap = new Map(
        [...scenarios, ...state.projectsTabs[projectId].scenarios].map(
          (tab) => [tab.id, tab],
        ),
      );

      const uniqueIds = Array.from(new Set(commonTabsMap.keys()));

      state.projectsTabs[projectId].scenarios = uniqueIds.map(
        (id) => commonTabsMap.get(id) as IScenario,
      );

      state.projectsTabs[projectId].currentId = scenarios[0].id;
    },

    openScenarioTab: (
      state,
      {
        payload: { scenario, projectId },
      }: PayloadAction<{ scenario: IScenario; projectId: string }>,
    ) => {
      const project = state.projectsTabs[projectId];

      state.projectsTabs[projectId] = {
        currentId: scenario.id,
        scenarios: project?.scenarios.some(({ id }) => id === scenario.id)
          ? project?.scenarios
          : [scenario, ...(project?.scenarios ?? [])],
      };
    },

    closeAllScenarios: (
      state,
      { payload: projectId }: PayloadAction<string>,
    ) => {
      state.projectsTabs[projectId] = {
        currentId: null,
        scenarios: [],
      };
    },

    editScenario: (
      state,
      {
        payload: {
          id,
          data: { name, visibility, updated_at },
          projectId,
        },
      }: PayloadAction<{
        id: string;
        data: Partial<Pick<IScenario, 'name' | 'visibility' | 'updated_at'>>;
        projectId: string;
      }>,
    ) => {
      const project = state.projectsTabs[projectId];

      state.projectsTabs[projectId] = {
        currentId: id,
        scenarios:
          project?.scenarios?.reduce((tabs, tab) => {
            if (tab.id === id) {
              return [
                ...tabs,

                {
                  ...tab,
                  ...(name ? { name } : {}),
                  ...(visibility ? { visibility } : {}),
                  ...(updated_at ? { updated_at } : {}),
                },
              ];
            }

            if (tab.source?.id === id) {
              return [
                ...tabs,
                {
                  ...tab,
                  source: { ...tab.source, ...(name ? { name } : {}) },
                },
              ];
            }

            return [...tabs, tab];
          }, [] as IScenario[]) ?? [],
      };
    },

    closeScenario: (
      state,
      {
        payload: { scenarioId, projectId },
      }: PayloadAction<{ scenarioId: string; projectId: string }>,
    ) => {
      const tabs = state.projectsTabs[projectId];
      const prevNextTabId =
        tabs?.scenarios?.find(
          (_, index, array) =>
            array[index - 1]?.id === scenarioId ||
            array[index + 1]?.id === scenarioId,
        )?.id ?? null;

      state.projectsTabs[projectId] = {
        currentId:
          scenarioId === tabs?.currentId ? prevNextTabId : tabs?.currentId,
        scenarios: tabs?.scenarios?.filter(({ id }) => id !== scenarioId) ?? [],
      };
    },

    clearProjectTabs: (state) => {
      state.projectsTabs = {};
    },
  },
});

export const selectProjectTabs = (state: RootState) =>
  state.project.projectsTabs;

export const {
  setCreatedScenario,
  pushScenarioTabs,
  setCurrentScenario,
  editScenario,
  closeScenario,
  closeAllScenarios,
  openScenarioTab,
  clearProjectTabs,
} = projectSlice.actions;
export default projectSlice.reducer;
