import { OmitId } from '@constants/types';
import {
  AssuranceControl,
  ConceptualMeasurement,
  ConceptualSource,
  LogicalPolicy,
  Name,
  NameLink,
  NameObjective,
  Rule,
} from '@store/services/nodes/types';

export const NameLinkObject: Omit<NameLink, 'id'> = {
  name: '',
  link: '',
};

export const ProgressNoteObject: Omit<Name, 'id'> = {
  name: '',
};

export const NameObjectiveObject: Omit<NameObjective, 'id'> = {
  name: '',
  objective: '',
};

export const AssuranceControlObject: AssuranceControl = {
  name: '',
  criterias: [],
  same_as_entity_assurance: false,
};

export type PhysicalRulesFormValues = Omit<Rule, 'control'> & {
  control: (typeof AssuranceControlObject)[];
};

export const RuleObject: OmitId<PhysicalRulesFormValues> = {
  name: '',
  link: '',
  control: [AssuranceControlObject],
};

export type LogicalPolicyFormValues = Omit<LogicalPolicy, 'requirement'> & {
  requirement: (typeof AssuranceControlObject)[];
};

export const PolicyObject: OmitId<LogicalPolicyFormValues> = {
  name: '',
  link: '',
  requirement: [AssuranceControlObject],
};

export type ConceptualSourceFormValues = Omit<
  ConceptualSource,
  'id' | 'justification_statement'
> & { justification_statement: (typeof JustificationStatementObject)[] };

export const JustificationStatementObject = {
  name: '',
};

export const ConceptualSourceObject: ConceptualSourceFormValues = {
  type: '',
  name: '',
  justification_statement: [JustificationStatementObject],
  link: '',
};

export const ConceptualMeasurementObject: OmitId<ConceptualMeasurement> = {
  approach: '',
  metric_type: '',
  metric_output: '',
  target: '',
  primary_threshold: '',
  secondary_threshold: '',
};
