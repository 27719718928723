import { useEffect } from 'react';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useAppDispatch } from '@hooks/store';
import useAssets from '@hooks/useAssets';
import useProjectId from '@hooks/useProjectId';
import useScenarioMode from '@hooks/useScenarioMode';
import { resetAssetId } from '@store/slices/assetsSlice';
import useAssetsList from '@views/Project/hooks/useAssetsList';

const useCurrentAssetInitialize = () => {
  const projectScenarioId = useProjectId(true);
  const projectId = useProjectId();
  const { scenarioMode } = useScenarioMode();

  const {
    activeVersionId,
    toolbox: { mode },
  } = useProject();

  const dispatch = useAppDispatch();
  const { selectAsset, selectedAssetId } = useAssets();
  const { assetOptions, isLoading } = useAssetsList();

  useEffect(() => {
    return () => {
      dispatch(resetAssetId(projectId));
    };
  }, [mode, projectScenarioId, scenarioMode, activeVersionId]);

  useEffect(() => {
    const shouldSetFirstAsset =
      mode === Modes.RiskManagement &&
      !isLoading &&
      assetOptions.length &&
      !selectedAssetId;

    if (shouldSetFirstAsset) {
      selectAsset(assetOptions[0].value as string);
    }
  }, [mode, assetOptions, selectedAssetId]);
};

export default useCurrentAssetInitialize;
