import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { ISelectOption } from '@constants/entities/ui';
import { AssuranceControlCriteria } from '@store/services/nodes/types';

const useSameCheck = (checkName: string, criteriaName: string) => {
  const { watch, getValues, setValue } = useFormContext();
  const isSameChecked = watch(checkName);

  const handleSameChanged = (isSame: boolean) => {
    if (isSame) {
      const nodeAssurance = getValues(CommonKeys.Assurance);

      setValue(
        criteriaName,
        Object.entries<ISelectOption[]>(nodeAssurance).reduce<
          AssuranceControlCriteria[]
        >((acc, [criteriaId, answers]) => {
          if (!answers?.length) return acc;

          return [
            ...acc,
            {
              id: criteriaId,
              answers: answers.map(({ value }) => value as string),
            },
          ];
        }, []),
        { shouldValidate: true, shouldDirty: true },
      );
    }
  };

  return { isSameChecked, handleSameChanged };
};

export default useSameCheck;
