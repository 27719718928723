import React, { FC } from 'react';
import { ListItemProps } from '@components/Inputs/Autocomplete/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { Checkbox, MenuItem } from '@mui/material';
import { AssuranceControlCriteria } from '@store/services/nodes/types';

const CriteriaListItem: FC<
  ListItemProps & {
    criteriaValue: AssuranceControlCriteria;
    onLevelClick: () => void;
    onAnswerClick: (answer: string) => void;
  }
> = ({ option, criteriaValue, onLevelClick, onAnswerClick }) => {
  const selected = Boolean(criteriaValue);

  return (
    <>
      <MenuItem
        component="li"
        sx={{ color: 'grey.1000' }}
        onClick={onLevelClick}
      >
        <Checkbox checked={selected} />

        <TypographyWithElipsis
          variant="body1"
          color="inherit"
          sx={{ width: '100%' }}
        >
          {option.label}
        </TypographyWithElipsis>
      </MenuItem>

      {option.answers.map((answer: string) => {
        return (
          <MenuItem
            key={option.value + answer}
            component="li"
            sx={{ color: 'grey.1000', ml: 3 }}
            onClick={() => onAnswerClick(answer)}
          >
            <Checkbox checked={!!criteriaValue?.answers.includes(answer)} />

            <TypographyWithElipsis
              variant="body1"
              color="inherit"
              sx={{ width: '100%' }}
            >
              {answer}
            </TypographyWithElipsis>
          </MenuItem>
        );
      })}
    </>
  );
};

export default CriteriaListItem;
