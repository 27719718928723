import React, { FC } from 'react';
import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import { ComponentKeys } from '@components/EntityDrawers/drawers/Component/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const AuditFindingInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Audit finding name"
      placeholder="Audit finding name"
      disabled={viewOnly}
      multiline
    />

    <ControlledTextField
      name={createName('link')}
      label="Audit finding objective"
      placeholder="Audit finding objective"
      disabled={viewOnly}
      multiline
    />
  </>
);

const AuditFindingGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={ComponentKeys.AuditFinding}
      viewOnly={viewOnly}
      groupObject={NameLinkObject}
      {...getInputGroupsProps('audit finding')}
    >
      {AuditFindingInputs}
    </ControlledInputGroups>
  );
};

export default AuditFindingGroup;
