import { useFormContext } from 'react-hook-form';
import { LogicalKeys } from '@components/EntityDrawers/drawers/Logical/keys';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import { ISelectOption } from '@constants/entities/ui';
import { LogicalPolicy, Rule } from '@store/services/nodes/types';

const useAssuranceChange = (
  fieldsName:
    | LogicalKeys.Policies
    | PhysicalKeys.Standards
    | PhysicalKeys.TechInstructions,
  controlsName: LogicalKeys.Requirement | PhysicalKeys.Control,
) => {
  const { setValue, getValues } = useFormContext();

  const handleAssuranceChange = (
    criteriaId: string,
    values: ISelectOption[],
  ) => {
    if (!fieldsName || !controlsName) return;
    const fields = getValues(fieldsName) as Rule[] | LogicalPolicy[];

    fields.forEach((field, fieldIndex) => {
      (field as Rule)[controlsName as PhysicalKeys.Control].forEach(
        ({ same_as_entity_assurance, name, criterias }, controlIndex) => {
          if (same_as_entity_assurance) {
            const isExist = criterias.some((cr) => cr.id === criteriaId);

            const newCriteria = {
              id: criteriaId,
              answers: values.map(({ value }) => value),
            };

            const newCriterias = isExist
              ? criterias.map((cr) => (cr.id === criteriaId ? newCriteria : cr))
              : [...criterias, newCriteria];

            setValue(
              `${fieldsName}[${fieldIndex}].${controlsName}[${controlIndex}].criterias`,
              newCriterias,
              { shouldDirty: true, shouldValidate: true },
            );
          }
        },
      );
    });
  };

  return { handleAssuranceChange };
};

export default useAssuranceChange;
