import { useCallback, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import useDrawerInfo from '@components/EntityDrawers/hooks/useDrawerIfo';
import useNodeFormReset, {
  NodeFormResetOptions,
} from '@components/EntityDrawers/hooks/useNodeFormReset';
import useNodeSave, {
  NodeSaveOptions,
} from '@components/EntityDrawers/hooks/useNodeSave';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import useTransformContext from '@components/EntityDrawers/hooks/useTransformContext';
import useUpdateNode from '@components/EntityDrawers/hooks/useUpdateNode';
import useStore from '@components/MainStage/store';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectDrawerChanges from '@context/Project/useProjectDrawerChanges';
import { yupResolver } from '@hookform/resolvers/yup';
import useResetViewportOnNodeDrawer from '@hooks/canvas/useResetViewportOnNodeDrawer';
import useProjectData from '@hooks/useProjectData';
import useTwoDatesValidation from '@hooks/useTwoDatesValidation';
import { ISettings } from '@store/services/projects/types';
import { Tabs } from '@views/Insights/types';

export type EntityDrawerOptions<FormValuesType, MetaType> = Pick<
  NodeFormResetOptions<FormValuesType & FieldValues, MetaType>,
  'transformNodeDtoToFormValues' | 'defaultValues'
> &
  Pick<
    NodeSaveOptions<FormValuesType & FieldValues, MetaType>,
    'transformMetaDataToRequestBody'
  > & {
    validationSchema: any;
    type: ProjectDrawerTypes;
    editType: ProjectDrawerTypes;
    checkIncompleteFields: (
      values: FormValuesType,
      projectSettings?: ISettings,
    ) => boolean;
  };

const useNodeDrawer = <FormValuesType, MetaType>({
  type,
  editType,
  defaultValues,
  validationSchema,
  transformNodeDtoToFormValues,
  transformMetaDataToRequestBody,
  checkIncompleteFields,
}: EntityDrawerOptions<FormValuesType, MetaType>) => {
  const { updateNodeLabel } = useStore();
  const { handleCloseDrawer, insightsTab } = useProject();
  const [{ settingsData }] = useProjectData();
  const activeNode = useSourceNode();
  const resetViewPort = useResetViewportOnNodeDrawer();

  const { open, editMode, drawerTitle, insightsMode, badge } = useDrawerInfo({
    type,
    editType,
  });

  const { context } = useTransformContext();

  const form = useForm<FormValuesType & FieldValues>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema) as any,
    defaultValues:
      typeof defaultValues === 'function'
        ? defaultValues(context)
        : defaultValues,
  });

  const {
    watch,
    formState: { errors, dirtyFields },
  } = form;

  const isDirty = Object.keys(dirtyFields).length > 0;
  const name = watch(CommonKeys.Name as any);

  useEffect(() => {
    const { id = '', data } = activeNode ?? {};

    updateNodeLabel(id, open && name ? name : data?.dto?.name ?? '');
  }, [name, open, activeNode?.id]);

  useProjectDrawerChanges(isDirty, open);

  useTwoDatesValidation({
    form,
    startKeyName: 'dateCreated',
    endKeyName: 'dateReviewed',
    canBeEqual: true,
    error: () => `Review date should be later the creation date.`,
    ignore: [
      ProjectDrawerTypes.AddAssociatedRiskEntity,
      ProjectDrawerTypes.EditAssociatedRiskEntity,
    ].includes(type),
  });

  useNodeFormReset<FormValuesType & FieldValues, MetaType>({
    open,
    form,
    editMode,
    defaultValues,
    transformNodeDtoToFormValues,
  });

  const { handleUpdateNode, isUpdating, modalsProps } = useUpdateNode();

  const { handleSave, isLoading } = useNodeSave<
    FormValuesType & FieldValues,
    MetaType
  >({
    form,
    editMode,
    transformMetaDataToRequestBody,
    onUpdate: handleUpdateNode,
  });

  const isFormValid =
    !!watch(CommonKeys.Name as any)?.length && !Object.keys(errors).length;

  const isSaveDisabled = !isFormValid || isLoading || isUpdating || !isDirty;

  const isIncompleteMessageShown = checkIncompleteFields?.(
    watch(),
    settingsData,
  );

  const handleClose = useCallback(async () => {
    await handleCloseDrawer(
      false,
      insightsTab !== Tabs.FocusOn && insightsTab !== Tabs.Maturity,
    );

    resetViewPort();
  }, [handleCloseDrawer, insightsTab, resetViewPort]);

  return {
    form,
    handleSave,
    isLoading: isLoading || isUpdating,
    isSaveDisabled,
    isIncompleteMessageShown,
    isReviewRequired: activeNode?.data?.dto?.review_required,
    handleClose,
    insightsMode,
    drawerTitle,
    badge,
    open,
    editMode,
    modalsProps,
    activeNode,
  };
};

export default useNodeDrawer;
