import React, { FC } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import useCriteriaInput from '@components/EntityDrawers/components/ControlAssurance/hooks/useCriteriaInput';
import { MAX_VISIBLE_CHIP_SYMBOLS } from '@components/EntityDrawers/components/ReferencesInputsGroup/constants/values';
import { Autocomplete } from '@components/Inputs/Autocomplete';
import CriteriaListItem from '@components/Inputs/Autocomplete/items/CriteriaListItem';
import { Chip } from '@mui/material';

type Props = {
  name: string;
  isSame: boolean;
};

const CriteriaInput: FC<Props> = ({ name, isSame }) => {
  const { options, getLevelProps, values, handleLevelRemove } =
    useCriteriaInput(name);

  return (
    <Autocomplete
      options={options}
      value={values}
      multiple
      readOnly={isSame}
      label="Assurance level"
      placeholder="Assurance level"
      inputReadOnly
      itemComponent={CriteriaListItem}
      itemProps={({ value: criteriaId }) => getLevelProps(criteriaId)}
      disableClearable
      renderTags={(value) => {
        return value.map(({ value, label }) => {
          return (
            <CustomTooltip
              key={value}
              placement="left"
              disableHoverListener={label?.length < MAX_VISIBLE_CHIP_SYMBOLS}
              title={label}
            >
              <Chip
                label={label}
                size="small"
                variant="grey"
                disabled={isSame}
                sx={{ cursor: 'pointer' }}
                onDelete={() => handleLevelRemove(value)}
              />
            </CustomTooltip>
          );
        });
      }}
    />
  );
};

export default CriteriaInput;
