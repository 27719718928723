import { Edge, Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import {
  CiaSubLayers,
  SubLayersWithoutValidStatus,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { WideDomainKey } from '@constants/entities/ui';
import { filtersSubject } from '@context/ProjectFilters/FiltersSubject';
import { isCriteriaFieldsMatch } from '@utils/canvasHelpers';

class CanvasMap {
  nodes: Node[];

  relations: Edge[];

  nodeMap: Map<string, Node>;

  relationMap: Map<string, Edge>;

  constructor() {
    const { nodes, edges } = useStore.getState();

    this.nodes = nodes;
    this.relations = edges;
    this.nodeMap = new Map(nodes.map((node) => [node.id, node]));
    this.relationMap = new Map(edges.map((edge) => [edge.id, edge]));

    this.subscribeToCanvas();
  }

  private subscribeToCanvas() {
    useStore.subscribe((state) => {
      this.nodes = state.nodes;
      this.relations = state.edges;

      this.nodeMap = new Map(state.nodes.map((node) => [node.id, node]));
      this.relationMap = new Map(state.edges.map((edge) => [edge.id, edge]));
    });
  }

  isNodeHiddenByFilters(id: string) {
    const {
      domains,
      reviewStatus,
      validationStatus,
      cia,
      operationalStatuses,
    } = filtersSubject.getValue();

    const node = this.nodeMap.get(id);
    if (!node) return false;

    if (domains.length) {
      const isCustomNode = node.type === NodesTypes.CustomNode;
      const isDomainIncluded = domains.includes(
        node.data?.dto?.domain_id ?? WideDomainKey,
      );

      return isCustomNode && !isDomainIncluded;
    }

    if (reviewStatus.length) {
      return !(
        node.type !== NodesTypes.CustomNode || node.data?.dto?.review_required
      );
    }

    if (validationStatus.length) {
      const nodeWithoutValidStatus = SubLayersWithoutValidStatus.includes(
        node.parentNode as SubLayerTypes,
      );

      const isVisible =
        node.type !== NodesTypes.CustomNode ||
        validationStatus.includes(node.data?.dto?.valid_status);

      return nodeWithoutValidStatus || !isVisible;
    }

    if (
      Object.values(cia).some((fields) =>
        Object.values(fields).some((answers) => answers?.length),
      )
    ) {
      const isCiaSublayer = CiaSubLayers.includes(node.parentNode as any);

      if (!isCiaSublayer) return false;

      const isAssuranceMatch = isCriteriaFieldsMatch(
        cia.assurance_level,
        node.data.dto.cia?.assurance_level?.criterias,
      );

      const isCoverageMatch = isCriteriaFieldsMatch(
        cia.coverage,
        node.data.dto.cia?.coverage?.criterias,
        { passIfNodeFieldEmpty: true },
      );

      const isApplicabilityMatch = isCriteriaFieldsMatch(
        cia.applicability,
        node.data.dto.cia?.applicability?.criterias,
        { passIfNodeFieldEmpty: true },
      );

      const isAllMatch =
        isAssuranceMatch && isCoverageMatch && isApplicabilityMatch;

      return !isAllMatch;
    }

    if (operationalStatuses.length) {
      const isCiaSublayer = CiaSubLayers.includes(node.parentNode as any);
      if (!isCiaSublayer) return false;

      const isStatusMatch = operationalStatuses.includes(
        node.data.dto?.meta_data?.operational_status,
      );

      return !isStatusMatch;
    }

    return false;
  }
}

export const canvasMap = new CanvasMap();
