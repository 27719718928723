// *We consider key as common if it is used in more than one entity

export enum CommonKeys {
  Name = 'name',
  Owner = 'owner',
  Domain = 'domain',
  Description = 'description',
  DateCreated = 'dateCreated',
  DateReviewed = 'dateReviewed',
  ValidationStatus = 'validationStatus',
  QnousRef = 'qnous_ref',
  NistRef = 'nist',
  IsoRef = 'iso',
  IsmRef = 'ism',
  OperationalStatus = 'operational_status',
  TimelineStartDate = 'timelineStartDate',
  TimelineEndDate = 'timelineEndDate',
  Metrics = 'metrics',
  CurrentMaturity = 'current_maturity',
  TargetMaturity = 'target_maturity',
  OperationalModel = 'operational_model',
  OperationalEffectiveness = 'operational_effectiveness',
  DesignEffectiveness = 'design_effectiveness',
  Assurance = 'assurance_level',
  Coverage = 'coverage',
  Applicability = 'applicability',
}

export type CoreFormKeysType = typeof CoreFormKeys;
export const CoreFormKeys = [
  CommonKeys.Name,
  CommonKeys.Owner,
  CommonKeys.Domain,
  CommonKeys.ValidationStatus,
  CommonKeys.DateCreated,
  CommonKeys.DateReviewed,
] as const;

export const CoreRequiredKeys = [
  CommonKeys.Name,
  CommonKeys.Domain,
  CommonKeys.Owner,
] as const;

export type LogicPhysicalFormKeysType = typeof LogicPhysicalKeys;
export const LogicPhysicalKeys = [
  ...CoreFormKeys,
  CommonKeys.QnousRef,
  CommonKeys.NistRef,
  CommonKeys.IsoRef,
  CommonKeys.IsmRef,
  CommonKeys.Description,
  CommonKeys.OperationalStatus,
  CommonKeys.TimelineStartDate,
  CommonKeys.TimelineEndDate,
  CommonKeys.Metrics,
  CommonKeys.CurrentMaturity,
  CommonKeys.TargetMaturity,
  CommonKeys.OperationalModel,
  CommonKeys.OperationalEffectiveness,
  CommonKeys.DesignEffectiveness,
  CommonKeys.Assurance,
  CommonKeys.Coverage,
  CommonKeys.Applicability,
] as const;
