import { Node } from 'reactflow';
import {
  baseLayersProperties,
  LAYER_HEIGHT,
  NodesTypes,
} from '@constants/canvas/general';
import {
  ComponentSubLayerTypes,
  ConceptualSubLayerTypes,
  InitiativesSubLayerTypes,
  LayerTypes,
  LogicalSubLayerTypes,
  PhysicalSubLayerTypes,
  ReadableLayerTitles,
  ReadableSubLayerTitles,
} from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import SublayerView from '@utils/canvas/SublayerView';

export const CONTROLS_LAYERS = [
  NodeDTOTypes.BusinessAttribute,
  NodeDTOTypes.SecurityAttribute,
  NodeDTOTypes.Service,
  NodeDTOTypes.Capabilities,
  NodeDTOTypes.Functions,
  NodeDTOTypes.Technologies,
  NodeDTOTypes.Initiatives,
];

export const CONTROLS_PARENT_LAYER: any = {
  [NodeDTOTypes.BusinessAttribute]: NodeDTOTypes.StrategicEnabler,
  [NodeDTOTypes.SecurityAttribute]: NodeDTOTypes.BusinessAttribute,
  [NodeDTOTypes.Service]: NodeDTOTypes.SecurityAttribute,
  [NodeDTOTypes.Capabilities]: NodeDTOTypes.Service,
  [NodeDTOTypes.Functions]: NodeDTOTypes.Capabilities,
  [NodeDTOTypes.Technologies]: NodeDTOTypes.Functions,
  [NodeDTOTypes.Initiatives]: '',
};

export const LogicalLayer: Node = {
  id: LayerTypes.Logical,
  type: NodesTypes.LayerNode,
  data: { label: ReadableLayerTitles[LayerTypes.Logical] },
  position: { x: 0, y: LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const PhysicalLayer: Node = {
  id: LayerTypes.Physical,
  type: NodesTypes.LayerNode,
  data: { label: ReadableLayerTitles[LayerTypes.Physical] },
  position: { x: 0, y: 2 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const ComponentLayer: Node = {
  id: LayerTypes.Component,
  type: NodesTypes.LayerNode,
  data: { label: ReadableLayerTitles[LayerTypes.Component] },
  position: { x: 0, y: 3 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

const controlsLayerNodes: Node[] = [
  {
    id: LayerTypes.Conceptual,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.Conceptual] },
    position: { x: 0, y: 0 },
    ...baseLayersProperties,
  },
  {
    ...LogicalLayer,
    position: { x: 0, y: LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
  },
  {
    ...PhysicalLayer,
    position: { x: 0, y: 2 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
  },
  {
    ...ComponentLayer,
    position: { x: 0, y: 3 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
  },
  {
    id: LayerTypes.Initiatives,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.Initiatives] },
    position: { x: 0, y: 4 * LAYER_HEIGHT + 6 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const ServiceSublayer: Node = {
  id: LogicalSubLayerTypes.Services,
  type: NodesTypes.SubLayerNode,
  data: { label: ReadableSubLayerTitles[LogicalSubLayerTypes.Services] },
  position: { x: 0, y: 2 * LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const CapabilitiesSublayer: Node = {
  id: PhysicalSubLayerTypes.Capabilities,
  type: NodesTypes.SubLayerNode,
  data: {
    label: ReadableSubLayerTitles[PhysicalSubLayerTypes.Capabilities],
    withBottomBorder: true,
  },
  position: { x: 0, y: 3 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const FunctionsSublayer: Node = {
  id: PhysicalSubLayerTypes.Functions,
  type: NodesTypes.SubLayerNode,
  data: { label: ReadableSubLayerTitles[PhysicalSubLayerTypes.Functions] },
  position: { x: 0, y: 3 * LAYER_HEIGHT + 4 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const TechnologiesSublayer: Node = {
  id: ComponentSubLayerTypes.Technologies,
  type: NodesTypes.SubLayerNode,
  data: {
    label: ReadableSubLayerTitles[ComponentSubLayerTypes.Technologies],
  },
  position: { x: 0, y: 4 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
  ...baseLayersProperties,
};

export const controlsSubLayerNodes: Node[] = [
  {
    id: ConceptualSubLayerTypes.BusinessAttributes,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[ConceptualSubLayerTypes.BusinessAttributes],
      withBottomBorder: true,
    },
    position: { x: 0, y: LAYER_HEIGHT },
    ...baseLayersProperties,
  },
  {
    id: ConceptualSubLayerTypes.SecurityAttributes,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[ConceptualSubLayerTypes.SecurityAttributes],
    },
    position: { x: 0, y: LAYER_HEIGHT + SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    ...ServiceSublayer,
    position: { x: 0, y: 2 * LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
  },
  {
    ...CapabilitiesSublayer,
    position: { x: 0, y: 3 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
  },
  {
    ...FunctionsSublayer,
    position: { x: 0, y: 3 * LAYER_HEIGHT + 4 * SublayerView.InitialHeight },
  },
  {
    ...TechnologiesSublayer,
    position: { x: 0, y: 4 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
  },
  {
    id: InitiativesSubLayerTypes.ChangeInitiatives,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[InitiativesSubLayerTypes.ChangeInitiatives],
    },
    position: { x: 0, y: 5 * LAYER_HEIGHT + 6 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const controlsBaseLayerNodes: Node[] = [
  ...controlsLayerNodes,
  ...controlsSubLayerNodes,
];
