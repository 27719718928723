import React, { FC, useMemo } from 'react';
import ListItemWithInfo from '@components/Inputs/Autocomplete/items/ListItemWithInfo';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project';
import { CIASettingsCriteriaType } from '@store/services/questionnaire/types';
import { transformArrayToOptions } from '@utils/helpers';

type Props = {
  field: CIASettingsCriteriaType;
  nameKey: string;
  changeHandler: (newValues: ISelectOption[]) => void;
};

const CriteriaField: FC<Props> = ({
  field: { name, answers },
  nameKey,
  changeHandler,
}) => {
  const { viewOnly } = useProject();

  const options = useMemo<ISelectOption[]>(() => {
    return transformArrayToOptions(answers);
  }, [answers]);

  return (
    <ControlledAutocomplete
      name={nameKey}
      label={name}
      placeholder={name}
      options={options}
      multiple
      readOnly={viewOnly}
      itemComponent={ListItemWithInfo}
      changeHandler={changeHandler}
      defValue={[]}
    />
  );
};

export default CriteriaField;
