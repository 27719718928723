import { useLocation, useNavigate } from 'react-router-dom';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import { useAppSelector } from '@hooks/store';
import useAssets from '@hooks/useAssets';
import useProjectId from '@hooks/useProjectId';
import { ROUTES } from '@router/routes';
import { selectProjectTabs } from '@store/slices/projectSlice';

type LeaveScenarioModeOptions = { withRedirect?: boolean } | void;

const useScenarioMode = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleCloseDrawer } = useProject();
  const id = useProjectId();
  const projectsTabs = useAppSelector(selectProjectTabs);
  const { resetAsset } = useAssets();
  const { saveCurrentZoom } = useCanvasZoom();

  const scenarioMode = pathname.includes('scenario');

  const enterScenarioMode = async () => {
    await handleCloseDrawer();
    resetAsset();
    saveCurrentZoom();

    const isNoTabs = !projectsTabs[id]?.scenarios.length;

    if (isNoTabs) {
      navigate(ROUTES.PROJECT.SCENARIOS.replace(':id', id));
    } else {
      navigate(ROUTES.PROJECT.SCENARIO.MODEL.replace(':id', id));
    }
  };

  const leaveScenarioMode = async ({
    withRedirect = true,
  }: LeaveScenarioModeOptions = {}) => {
    await handleCloseDrawer();
    resetAsset();
    saveCurrentZoom();

    if (withRedirect) {
      navigate(ROUTES.PROJECT.MODEL.replace(':id', id));
    }
  };

  return { scenarioMode, enterScenarioMode, leaveScenarioMode };
};

export default useScenarioMode;
