import { Edge, Node } from 'reactflow';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import useStore from '@components/MainStage/store';
import { EntityData } from '@components/MainStage/types';
import { SubLayerTypes } from '@constants/canvas/layers';
import { RefType } from '@constants/entities/project';
import useCurrentAsset from '@hooks/useCurrentAsset';
import useProjectData from '@hooks/useProjectData';
import useProjectSettings from '@hooks/useProjectSettings';
import useReferenceMaps from '@hooks/useReferenceMaps';
import { AssessmentMetaData, NodeDTO } from '@store/services/nodes/types';
import { ISettings } from '@store/services/projects/types';
import { CIASettingsType } from '@store/services/questionnaire/types';

export type TransformContext = {
  subLayer: SubLayerTypes | undefined;
  qnousMap: Map<string, string>;
  nistMap: Map<string, string>;
  isoMap: Map<string, string>;
  ismMap: Map<string, string>;
  settings: Partial<ISettings>;
  nodes: Node[];
  edges: Edge[];
  activeNode: Node<EntityData<NodeDTO<any>>> | undefined;
  asset: NodeDTO<AssessmentMetaData>;
  ciaSettings: CIASettingsType;
};

const useTransformContext = () => {
  const activeNode = useSourceNode() as Node<EntityData<NodeDTO<any>>>;
  const [{ qnousMap, nistMap, isoMap, ismMap }] = useReferenceMaps();
  const { settings } = useProjectSettings();
  const { nodes, edges } = useStore();
  const { dto: asset } = useCurrentAsset();
  const [{ questionnaire }] = useProjectData();

  const context: TransformContext = {
    activeNode,
    subLayer: activeNode?.parentNode as SubLayerTypes,
    qnousMap,
    nistMap,
    isoMap,
    ismMap,
    settings,
    nodes,
    edges,
    asset,
    ciaSettings: questionnaire?.cia_criteria ?? {
      assurance_level: { criterias: [] },
      coverage: { criterias: [] },
      applicability: { criterias: [] },
      prioritisation: {
        question_id: '',
        model: RefType.NIST,
        answer: '',
      },
      levels_based_on_questionnaire: false,
    },
  };

  return { context };
};

export default useTransformContext;
