import React, { FC } from 'react';
import { NameObjectiveObject } from '@components/EntityDrawers/constants/empty-objects';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const MetricsInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Metric name"
      placeholder="Metric name"
      disabled={viewOnly}
      multiline
    />

    <ControlledTextField
      name={createName('objective')}
      label="Metric objective"
      placeholder="Metric objective"
      disabled={viewOnly}
      multiline
    />
  </>
);

const MetricsGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={CommonKeys.Metrics}
      viewOnly={viewOnly}
      groupObject={NameObjectiveObject}
      {...getInputGroupsProps('metric')}
    >
      {MetricsInputs}
    </ControlledInputGroups>
  );
};

export default MetricsGroup;
