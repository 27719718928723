import { ISelectOption } from '@constants/entities/ui';
import {
  IQuestionAnswers,
  QuestionDependency,
} from '@store/services/questionnaire/types';

export enum FieldTypes {
  Text = 'text',
  SelectMultiple = 'select-multiple',
  SelectOne = 'select-one',
}

export type Field = {
  name: string;
  type: FieldTypes;
  required: boolean;
  label?: string;
  tooltip?: string;
  options?: ISelectOption[];

  dependency: null | QuestionDependency;
  external_id: number;
  answers: IQuestionAnswers[];

  criteriaKey?: string;
};

export type QuestionBlock = {
  title: string;
  tooltip?: string;
  text?: string;
  fields: Field[];
  single: boolean;
};

export interface IFormValues {
  [key: string]: Record<string, string | string[] | IFormValues>;
}
