export enum ProjectStatus {
  Onboarding = 'onboarding',
  Engagement = 'engagement',
  OnPause = 'on_pause',
  PaymentDelay = 'payment_delay',
  Support = 'support',
  Archived = 'archived',
}

export const ReadableProjectStatus = {
  [ProjectStatus.Onboarding]: 'Onboarding',
  [ProjectStatus.Engagement]: 'Engagement',
  [ProjectStatus.OnPause]: 'On pause',
  [ProjectStatus.PaymentDelay]: 'Payment delay',
  [ProjectStatus.Support]: 'Support',
  [ProjectStatus.Archived]: 'Archived',
};

export enum RefType {
  NIST = 'nist',
  ISO = 'iso',
  ISM = 'ism',
}

export const RefTypeNames = {
  [RefType.NIST]: 'NIST',
  [RefType.ISO]: 'ISO',
  [RefType.ISM]: 'ISM',
};

export const refOptions = Object.values(RefType).map((ref) => ({
  label: RefTypeNames[ref],
  value: ref,
}));
