import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import {
  EntityValidationStatus,
  OperationalStatus,
} from '@constants/canvas/layers';
import { EnterpriseRolesType } from '@constants/entities/user';
import { emailRegex } from '@constants/login';
import { filterFilledUsers } from '@views/Projects/components/CreateProjectModal/helpers/filterFilledUsers';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

export const allowedImageFormats = ['image/jpeg', 'image/jpg', 'image/png'];
export const MaxImgFileSize = 500 * 1024; // 500 KB
export const DefaultMatrixSize = 5;
export const MinMatrixSize = 3;
export const MaxMatrixSize = 6;
export const MinMatrixValue = 1;
export const MaxMatrixValue = 36;

export const DefaultError = 'Something went wrong. Try again.';
export const TimeLineDateError =
  'Renewal/end date should be later than the Start/expected date';
export const RequiredError = 'Please fill in all required fields';
export const RequiredErrorSingular = 'Please fill in required field';
export const EmailError =
  'Email address should be in format example@example.com';
export const UserEmailExistsError =
  'User with such email address already exists';
export const OnlyLatinError = 'Field should contain Latin characters';
export const RiskAssessmentScoreRangeError = `Risk score should be within the range of ${MinMatrixValue} to ${MaxMatrixValue}`;
export const LicensesMinError = 'Number of user licenses cannot equal 0.';

export const OnlyTwoSymbolsAfterComaValidation = /^\d+(\.\d{1,2})?$/;
export const OnlyTwoSymbolsAfterDot = /^(?!0\d)(\d+(\.\d{1,2})?)?$/;
export const QnousEmail = /^[^@]+@qnous\.io$/;
export const LettersValidation = {
  1: /^[A-Za-z-' ]+$/,
  2: /^[A-Za-z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]*$/,
};

export const NotDigits = /\D/g;
export const LastBraces = /\(([^()]*)\)$/;

export const MaxDescriptionLength = 512;

export const twoWordsCb = (value: string | undefined) => {
  const words = value?.trim().split(/\s+/) ?? [];
  return words.length >= 2;
};

export const withoutNonLatinLettersCb = (value: string | undefined) => {
  const hasNonLatinLetters =
    // eslint-disable-next-line no-control-regex
    /[^\u0000-\u007F\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]/.test(value ?? '');
  return !hasNonLatinLetters;
};

export const createDuplicateTest = <T>(
  errorMessage: string,
  fieldName = 'name',
): yup.TestConfig<T> => {
  return {
    name: `unique-item-${fieldName}`,
    message: errorMessage,
    exclusive: true,
    test(currentItem?: any) {
      const { parent: items, createError, path } = this;

      const itemIterator = (item: any) => {
        const isNameFilled = Boolean(item?.[fieldName]);
        const isCurrentDomain = item?.id === currentItem?.id;
        const isNameSame =
          item?.[fieldName]?.trim().toLowerCase() ===
          currentItem?.[fieldName]?.trim().toLowerCase();

        return isNameFilled && !isCurrentDomain && isNameSame;
      };

      if (currentItem && items?.some(itemIterator)) {
        return createError({
          path: `${path}.${fieldName}`,
          message: errorMessage,
        });
      }

      return true;
    },
  };
};

export const YupSelectOptions = yup.array().of(yup.object());
export const YupTextSelectOption = yup.string().nullable();

export const YupFullName = yup
  .string()
  .min(2, 'Full name must be at least 2 characters')
  .max(100, 'Full name must be at most 100 characters')
  .test(
    'Full Name',
    'Full name should contain only Latin characters',
    withoutNonLatinLettersCb,
  )
  .matches(
    LettersValidation[1],
    'Full name should contain combination of alphabetical characters and hyphens or apostrophes.',
  )
  .test(
    'Full Name',
    'Full name should contain minimum of two words',
    twoWordsCb,
  );

export const YupString = (
  min = 1,
  max = 255,
  message = 'The field may contain Latin characters, digits and special characters',
) =>
  yup
    .string<string>()
    .trim()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(LettersValidation[2], message)
    .min(min, `Should contain at least ${min} symbol${min > 1 ? 's' : ''}`)
    .max(max, `Should contain not more ${max} symbol${max > 1 ? 's' : ''}`);

export const YupValidationStatus = () =>
  yup
    .string()
    .oneOf([EntityValidationStatus.Draft, EntityValidationStatus.Validated]);

export const YupDayJs = () =>
  yup.mixed<Dayjs>().test('is-dayjs', 'Date must be a valid date', (value) => {
    if (!value) return true;

    return dayjs.isDayjs(value);
  });

export const YupCriteriaField = yup
  .object()
  .test(
    'is-valid-object',
    'The object must have keys as strings and values as arrays of strings',
    (value) => {
      // Ensure the value is an object
      if (!value || typeof value !== 'object' || Array.isArray(value)) {
        return false;
      }

      // Check that every value is an array of strings
      return Object.values(value).every(
        (val) =>
          Array.isArray(val) && val.every((item) => typeof item === 'string'),
      );
    },
  );

const MaturityTypeError =
  'Maturity should contain only numbers up to two decimal places, always within the range 1 to 5. The decimal point can be represented either by a comma or a dot.';

const MaturityMinMaxError =
  'Maturity should contain only numbers up to two decimal places, always within the range 1 to 5.';

export const YupMaturity = () =>
  yup
    .number()
    .nullable()
    .transform((_, o) => {
      return o ? Number(o?.replace(',', '.')) : null;
    })
    .typeError(MaturityTypeError)
    .min(1, MaturityMinMaxError)
    .max(5, MaturityMinMaxError)
    .test(
      'maxDigitsAfterDecimal',
      'Maturity should contain only numbers up to two decimal places.',
      function (number) {
        if (this.originalValue?.startsWith('+')) {
          return this.createError({
            path: this.path,
            message: MaturityTypeError,
          });
        }

        return number
          ? OnlyTwoSymbolsAfterComaValidation.test(number.toString())
          : true;
      },
    );

export const YupTargetMaturity = () =>
  YupMaturity().test({
    name: 'target-maturity',
    message:
      'Target maturity should be equal to or greater than current maturity',
    test(targetMaturity, { parent }) {
      if (!targetMaturity) return true;

      if (!parent.current_maturity) {
        return true;
      }

      return targetMaturity >= parent.current_maturity;
    },
  });

const specialChars = `()?!/|\\.,@_-:;'“[]*&#%+=`
  .split('')
  .map((char) => `\\${char}`)
  .join('');

export const YupLink = () =>
  yup
    .string()
    .nullable()
    .trim()
    .transform((v, o) => (o === '' ? null : v))
    .matches(
      LettersValidation[2],
      'Field may contain Latin characters, digits and special characters',
    )
    .matches(
      new RegExp(`^[A-Za-z0-9${specialChars}]{1,255}$`),
      'Link is invalid',
    )
    .min(1, 'Input must be at least 1 character long')
    .max(255, 'Input must be no more than 255 characters long');

export const YupTimelineStartDate = () =>
  YupDayJs()
    .nullable()
    .test({
      name: 'status-dependant-date',
      exclusive: false,

      test(timelineStartDate) {
        const { operational_status, dateCreated } = this.parent;
        if (!operational_status) return true;

        if (!timelineStartDate?.isValid() || !dateCreated?.isValid()) {
          return true;
        }

        if (operational_status === OperationalStatus.InDevelopment) {
          if (timelineStartDate?.isBefore(dateCreated, 'day')) {
            return this.createError({
              path: 'timelineStartDate',
              message:
                'Expected date should either be equal to, or later, the entity creation date',
            });
          }

          return true;
        }

        if (timelineStartDate?.isAfter(dateCreated, 'day')) {
          return this.createError({
            path: 'timelineStartDate',
            message:
              'Start date should either be equal to, or before, the entity creation date',
          });
        }

        return true;
      },
    });

const nameObjectiveSchema = yup.object().shape({
  id: yup.string().required(),
  name: YupString(),
  objective: YupString(),
});

export const NameObjectivesYup = () => yup.array().of(nameObjectiveSchema);

const nameLinkSchema = yup.object().shape({
  id: yup.string().required(),
  name: YupString(),
  link: YupLink(),
});

export const NameLinksYup = () => yup.array().of(nameLinkSchema);

export const YupAssuranceControl = yup.object().shape({
  id: yup.string(),
  name: YupString(1, MaxDescriptionLength),
  same_as_entity_assurance: yup.boolean(),
  criterias: yup
    .array(
      yup.object().shape({
        id: yup.string(),
        answers: yup.array(yup.string()),
      }),
    )
    .optional(),
});

export const YupAssuranceControlsArray = yup.array(YupAssuranceControl);

const policySchema = yup.object().shape({
  id: yup.string().required(),
  name: YupString(),
  link: YupLink(),
  requirement: YupAssuranceControlsArray,
});

export const PolicyYup = () => yup.array().of(policySchema);

const ruleSchema = yup.object().shape({
  id: yup.string().required(),
  name: YupString(),
  link: YupLink(),
  control: YupAssuranceControlsArray,
});

export const RulesYup = () => yup.array().of(ruleSchema);

const progressNoteSchema = yup.object().shape({
  id: yup.string().required(),
  name: YupString(1, MaxDescriptionLength),
});

export const ProgressNoteYup = () => yup.array().of(progressNoteSchema);

const MAX_AMOUNT_LENGTH_WITHOUT_COMAS = 12;

export const AmountYup = () =>
  yup
    .string()
    .nullable()
    .test({
      name: 'max_15_chars',
      message: 'Amount should not exceed 15 characters',
      test: (value) => {
        if (!value) return true;

        return value.length <= MAX_AMOUNT_LENGTH_WITHOUT_COMAS;
      },
    })
    .test({
      name: 'zeroAmount',
      message: 'Amount should be greater than 0',
      test(amount) {
        if (!amount) return true;

        if (!OnlyTwoSymbolsAfterDot.test(amount)) {
          return this.createError({
            path: this.path,
            message: 'Only two digits after separator allowed',
          });
        }

        return parseFloat(amount) >= 1;
      },
    });

export const ResidualScoreYup = () =>
  yup.number().test({
    message:
      'Residual risk score should be equal to or be lower than inherent risk score.',
    test(value) {
      const values = this.parent;

      if (
        !values[RiskKeys.InherentScore] ||
        value === undefined ||
        !values[RiskKeys.InherentImpact] ||
        !values[RiskKeys.InherentLikelihood]
      ) {
        return true;
      }

      return value <= values[RiskKeys.InherentScore];
    },
  });

export const YupDateIdentified = () =>
  YupDayJs()
    .nullable()
    .test({
      name: 'is-before-or-equal-to-creation-date',
      message:
        'Date identified should either be equal to, or before, the entity creation date',
      exclusive: false,

      test(identifiedDate) {
        if (!identifiedDate?.isValid()) return true;
        const dateCreated = this.parent[CommonKeys.DateCreated]?.isValid()
          ? this.parent[CommonKeys.DateCreated]
          : dayjs();

        return (
          identifiedDate?.isSame(dateCreated) ||
          identifiedDate?.isBefore(dateCreated)
        );
      },
    });

export const YupRiskDateReviewed = () =>
  YupDayJs()
    .nullable()
    .test('date-range', 'Invalid date range', function (reviewedDate) {
      if (reviewedDate) {
        const currentDate = dayjs();

        if (reviewedDate.isAfter(currentDate)) {
          return this.createError({
            message: 'Date reviewed should be before the current date.',
            path: CommonKeys.DateReviewed,
          });
        }

        const createdDate = this.parent[CommonKeys.DateCreated];

        if (reviewedDate.isBefore(createdDate)) {
          return this.createError({
            message: 'Review date should be later the creation date.',
            path: CommonKeys.DateReviewed,
          });
        }
      }

      return true;
    });

export const YupSubDomain = yup.object().shape({
  id: yup.string().required(),
  name: YupString(2, 100).test(
    'require-if-authority',
    RequiredError,
    function (value) {
      const { parent } = this;

      const isAuthorityProvided = Boolean(parent?.authority?.trim());

      if (isAuthorityProvided) {
        return !!value?.trim();
      }

      return true;
    },
  ),
  authority: YupString(2, 100).test(
    'required-if-subdomain',
    RequiredError,
    function (value) {
      const { parent } = this;

      const isSubdomainProvided = Boolean(parent?.name?.trim());

      if (isSubdomainProvided) {
        return !!value?.trim();
      }

      return true;
    },
  ),
});

export const YupSubDomains = yup.array().of(YupSubDomain);

export const YupDomain = yup.object().shape({
  id: yup.string().required(),
  name: YupString(2, 100).required(RequiredError),
  authority: YupString(2, 100).required(RequiredError),
  subDomains: YupSubDomains,
});

export const YupDomains = yup.array().of(YupDomain);

export const YupCriteria = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: YupString(2, 100).required(RequiredErrorSingular),
    question: YupString(2, 300).required(RequiredErrorSingular),
    dependency: yup
      .object()
      .shape({
        questionId: yup.string(),
        answer: yup.string(),
      })
      .nullable()
      .optional(),
    answers: yup
      .array()
      .of(YupString(1, 100))
      .test(
        'required',
        'Each criteria should have at least two answers',
        (value?: (string | undefined | null)[]) => Number(value?.length) > 1,
      ),
  })
  .test(
    createDuplicateTest<any>(
      'Such criteria question already exists',
      'question',
    ),
  )
  .test(createDuplicateTest<any>('Such criteria name already exists', 'name'));

export const YupCriteriaList = yup.array().of(YupCriteria);

export const YupCriteriaWithoutQuestion = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: YupString(2, 100).required(RequiredErrorSingular),
    question: yup.string().nullable(),
    dependency: yup
      .object()
      .shape({
        questionId: yup.string(),
        answer: yup.string(),
      })
      .nullable()
      .optional(),
    answers: yup
      .array()
      .of(YupString(1, 100))
      .test(
        'required',
        'Each criteria should have at least two answers',
        (value?: (string | undefined | null)[]) => Number(value?.length) > 1,
      ),
  })
  .test(createDuplicateTest<any>('Such criteria name already exists', 'name'))
  .test(
    createDuplicateTest<any>(
      'Such criteria question already exists',
      'question',
    ),
  );

export const YupCriteriaListWithoutQuestion = yup
  .array()
  .of(YupCriteriaWithoutQuestion);

export const YupPrioritization = yup.object().shape({
  model: yup
    .string()
    .test('model-required-if-specified', RequiredError, (value, context) => {
      const { question_id, answer } = context.parent;
      const isOtherFieldSpecified = question_id || answer;
      // If there are other fields specified, this field is required.
      return (
        !isOtherFieldSpecified ||
        (value !== undefined && value !== null && value !== '')
      );
    })
    .optional(),
  question_id: yup
    .string()
    .test(
      'question_id-required-if-specified',
      RequiredError,
      (value, context) => {
        const { model, answer } = context.parent;
        const isOtherFieldSpecified = model || answer;
        return !isOtherFieldSpecified || (value !== undefined && value !== '');
      },
    )
    .optional(),
  answer: yup
    .string()
    .test('answer-required-if-specified', RequiredError, (value, context) => {
      const { model, question_id } = context.parent;
      const isOtherFieldSpecified = model || question_id;
      return !isOtherFieldSpecified || (value !== undefined && value !== '');
    })
    .optional(),
});

export const YupUser = yup
  .object()
  .shape({
    id: yup.string().required(),
    full_name: YupFullName.required(RequiredError),
    email: yup.string().matches(emailRegex, EmailError).required(RequiredError),
    business_unit: YupString(2, 100),
    role: yup.string().required(RequiredError),
  })
  .test(createDuplicateTest<any>(UserEmailExistsError, 'email'));

export const YupUsers = yup.array().of(YupUser);

export const YupNonEmptyUsers = yup
  .array()
  .transform(filterFilledUsers)
  .of(YupUser);

export const YupSensitive = yup
  .object()
  .shape({
    id: yup.string().required(),
    type: YupString(2, 100, OnlyLatinError).required(RequiredError),
    details: yup
      .array()
      .of(YupString(2, 100, OnlyLatinError))
      .test(
        'required',
        'Each sensitive element type should have at least one type detail',
        (value?: (string | undefined | null)[]) => !!value?.length,
      ),
  })
  .test(
    createDuplicateTest<any>(
      'Such sensitive element type already exists',
      'type',
    ),
  );

export const YupSensitiveElements = yup.array().of(YupSensitive);

export const YupRiskCriticalityLevel = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: YupString(2, 50, OnlyLatinError).required(RequiredError),
    rangeMin: yup.number(),
    rangeMax: yup.number(),
  })
  .test(
    createDuplicateTest<any>(
      'Such risk criticality level already exists',
      'name',
    ),
  );

export const YupRiskCriticalityLevels = yup.array().of(YupRiskCriticalityLevel);

const YupMatrixHeader = (duplicateError: string) =>
  yup
    .object()
    .shape({
      id: yup.string().required(),
      name: YupString(2, 25, OnlyLatinError).required(RequiredError),
    })
    .test(createDuplicateTest<any>(duplicateError, 'name'));

const YupMatrixHeadersRow = (duplicateError: string) =>
  yup.array().of(YupMatrixHeader(duplicateError));

export const YupRiskAssessmentMatrix = yup.array().of(
  yup.object().shape({
    id: yup.string().required(),

    row: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),

        value: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .min(MinMatrixValue, RiskAssessmentScoreRangeError)
          .max(MaxMatrixValue, RiskAssessmentScoreRangeError)
          .required(RequiredError),
      }),
    ),
  }),
);

export const YupRiskAssessment = yup.object().shape({
  likelihood: YupMatrixHeadersRow('Please choose a different likelihood value'),
  impact: YupMatrixHeadersRow('Please choose a different impact value'),

  matrix: YupRiskAssessmentMatrix,
});

export const YupAssetCriticality = yup
  .object()
  .shape({
    value: YupString(2, 100, OnlyLatinError).required(RequiredErrorSingular),
  })
  .test(
    createDuplicateTest<any>('Such asset criticality already exists', 'value'),
  );

export const YupAssetCriticalityLevels = yup.array().of(YupAssetCriticality);

export const YupRiskAction = yup
  .object()
  .shape({
    value: YupString(2, 50, OnlyLatinError).required(RequiredErrorSingular),
  })
  .test(createDuplicateTest<any>('Such risk action already exists', 'value'));

export const YupRiskActions = yup.array().of(YupRiskAction);

export const YupRiskTaxonomy = yup
  .object()
  .shape({
    value: YupString(2, 100, 'Field should contain Latin characters').required(
      RequiredErrorSingular,
    ),
  })
  .test(createDuplicateTest<any>('Such risk taxonomy already exists', 'value'));

export const YupRiskTaxonomies = yup.array().of(YupRiskTaxonomy);

export const YupOptionalEmail = yup
  .string()
  .test(
    'is-optional-email',
    EmailError,
    (value) => !value || emailRegex.test(value),
  );

export const YupTierLicense = (minValue?: number) =>
  yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(1, LicensesMinError)
    .required(RequiredError)
    .test(
      'cannot-reduce',
      'To prevent conflicts with existing activated enterprise users in organisations, the number of user licenses can only be increased.',
      function (value) {
        if (!minValue) return true;
        return value >= minValue;
      },
    );

export const YupAdditionalLicense = (
  role: EnterpriseRolesType,
  usersCount?: number,
) =>
  yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .test(
      'cannot-reduce',
      'Updating the number of additional licenses would require deactivating some enterprise users. Please offboard the required number of enterprise users first and then amend the number of licenses accordingly.',
      function (value: number | null | undefined) {
        if (!usersCount) return true;
        const { organization } = this.parent as any;

        return (
          Number(value ?? 0) >=
          (Number(usersCount) -
            Number(organization.tier_id?.tier?.settings?.[role]) ?? 0)
        );
      },
    );
