import { useMemo } from 'react';
import { useGetQnousRefsQuery } from '@store/services/qnous-ref';

const useReferenceMaps = (): [
  {
    qnousMap: Map<string, string>;
    nistMap: Map<string, string>;
    isoMap: Map<string, string>;
    ismMap: Map<string, string>;
  },
  boolean,
] => {
  const { data, isLoading, isFetching } = useGetQnousRefsQuery();
  const { qnous_ref, nist, ism, iso } = data ?? {};

  const qnousMap = useMemo(() => {
    return new Map(qnous_ref?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  const nistMap = useMemo(() => {
    return new Map(nist?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  const isoMap = useMemo(() => {
    return new Map(iso?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  const ismMap = useMemo(() => {
    return new Map(ism?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  return [{ qnousMap, nistMap, isoMap, ismMap }, isLoading || isFetching];
};

export default useReferenceMaps;
