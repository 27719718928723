import React, { FC } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { MAX_VISIBLE_CHIP_SYMBOLS } from '@components/EntityDrawers/components/ReferencesInputsGroup/constants/values';
import useChangeReference from '@components/EntityDrawers/components/ReferencesInputsGroup/hooks/useChangeReference';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import useQnousRefOptions from '@components/EntityDrawers/hooks/useQnousRefOptions';
import ListItemWithInfo from '@components/Inputs/Autocomplete/items/ListItemWithInfo';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { useProject } from '@context/Project';
import useReferenceMaps from '@hooks/useReferenceMaps';
import useUser from '@hooks/useUser';
import { Chip } from '@mui/material';

const QnousInput: FC = () => {
  const { viewOnly } = useProject();

  const [{ isQmsUser }] = useUser();

  const [{ qnousOptions }, isRefsLoading] = useQnousRefOptions();
  const [{ qnousMap }] = useReferenceMaps();

  const { handleChangeReference, isChangingReference } = useChangeReference();

  if (!isQmsUser) return null;

  return (
    <ControlledAutocomplete
      name={CommonKeys.QnousRef}
      label="Qnous reference"
      placeholder="Select reference"
      options={qnousOptions}
      loading={isRefsLoading}
      multiple
      required
      readOnly={viewOnly}
      itemComponent={ListItemWithInfo}
      onChange={handleChangeReference(CommonKeys.QnousRef)}
      isLoading={isChangingReference || isRefsLoading}
      renderTags={(value, getTagProps) => {
        return value.map(({ value, label }, index) => (
          <CustomTooltip
            key={value}
            placement="left"
            disableHoverListener={label.length < MAX_VISIBLE_CHIP_SYMBOLS}
            title={label || qnousMap.get(value)}
          >
            <Chip
              label={label || qnousMap.get(value)}
              size="small"
              variant="grey"
              sx={{ cursor: 'pointer' }}
              {...getTagProps({ index })}
            />
          </CustomTooltip>
        ));
      }}
    />
  );
};

export default QnousInput;
