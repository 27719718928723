import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ISelectOption } from '@constants/entities/ui';
import useProjectData from '@hooks/useProjectData';
import { AssuranceControlCriteria } from '@store/services/nodes/types';

const useCriteriaInput = (name: string) => {
  const [{ questionnaire }] = useProjectData();
  const { setValue } = useFormContext();
  const criteriaArray: AssuranceControlCriteria[] = useWatch({ name });

  const options = useMemo<(ISelectOption & { answers: string[] })[]>(() => {
    return (
      questionnaire?.cia_criteria.assurance_level.criterias.map(
        ({ id, name, answers }) => ({ value: id, label: name, answers }),
      ) ?? []
    );
  }, [questionnaire]);

  const handleLevelRemove = (criteriaId: string) => {
    setValue(
      name,
      criteriaArray.filter(({ id }) => id !== criteriaId),
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleLevelClick = (criteriaId: string, isSelected: boolean) => () => {
    if (isSelected) {
      return handleLevelRemove(criteriaId);
    }

    setValue(
      name,
      [
        ...criteriaArray,
        {
          id: criteriaId,
          answers:
            options.find(({ value }) => value === criteriaId)?.answers ?? [],
        },
      ],
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleAnswerClick = (
    criteriaId: string,
    selectedAnswers: string[] | undefined,
    answer: string,
  ) => {
    if (selectedAnswers) {
      return setValue(
        name,
        criteriaArray.reduce<AssuranceControlCriteria[]>((acc, criteria) => {
          if (criteria.id === criteriaId) {
            const newAnswers = selectedAnswers.includes(answer)
              ? selectedAnswers.filter((a) => a !== answer)
              : [...selectedAnswers, answer];

            if (newAnswers.length) {
              return [...acc, { ...criteria, answers: newAnswers }];
            }

            return acc;
          }

          return [...acc, criteria];
        }, []),
        { shouldValidate: true, shouldDirty: true },
      );
    }

    setValue(name, [...criteriaArray, { id: criteriaId, answers: [answer] }], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const getLevelProps = (criteriaId: string) => {
    const criteriaValue = criteriaArray.find(({ id }) => id === criteriaId);

    return {
      criteriaValue,
      onLevelClick: handleLevelClick(criteriaId, !!criteriaValue),
      onAnswerClick: (answer: string) =>
        handleAnswerClick(criteriaId, criteriaValue?.answers, answer),
    };
  };

  const values: ISelectOption[] = criteriaArray.map(({ id, answers }) => {
    const criteriaName =
      questionnaire?.cia_criteria.assurance_level.criterias.find(
        (c) => c.id === id,
      )?.name ?? id;

    return {
      value: id,
      label: `${criteriaName}: ${answers.join(', ')}`,
    };
  });

  return { getLevelProps, options, values, handleLevelRemove };
};

export default useCriteriaInput;
