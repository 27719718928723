import React, { FC } from 'react';
import { InsightKeys } from '@constants/entities/insights';
import useProjectData from '@hooks/useProjectData';
import { Skeleton, Stack } from '@mui/material';
import CriteriaFilterList from '@views/Project/components/Toolbox/CiaFilters/CriteriaFilterList';

const CiaFilters: FC = () => {
  const [{ questionnaire, subscription }, { isLoading }] = useProjectData();

  if (isLoading)
    return (
      <Stack>
        <Skeleton />
      </Stack>
    );

  if (!questionnaire || !subscription?.insights.includes(InsightKeys.CIA)) {
    return null;
  }

  return (
    <>
      <CriteriaFilterList
        filterKey="assurance_level"
        criteriaList={questionnaire.cia_criteria.assurance_level.criterias}
      />

      <CriteriaFilterList
        filterKey="coverage"
        criteriaList={questionnaire.cia_criteria.coverage.criterias}
      />

      <CriteriaFilterList
        filterKey="applicability"
        criteriaList={questionnaire.cia_criteria.applicability.criterias}
      />
    </>
  );
};

export default CiaFilters;
