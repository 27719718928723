import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ConfirmDialog from '@components/Dialogs/ConfirmDialog';
import { GroupWrapper } from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups.styled';
import useToggle from '@hooks/useToggle';
import {
  Button,
  IconButton,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { PlusIcon, TrashIcon } from '@utils/iconsDefs';

export type InputsBlockProps = Readonly<{
  groupNumber: number;
  createName: (fieldName: string) => string;
  viewOnly?: boolean;
}>;

export type ControlledInputGroupsProps = {
  name: string;
  title: string;
  groupObject: Record<string, any>;

  children: FC<InputsBlockProps>;

  deleteModal: {
    title: string;
    text: string;
  };

  addButtonName: string;
  viewOnly?: boolean;

  titleProps?: TypographyProps;
  wrapped?: boolean;
};

const ControlledInputGroups: FC<ControlledInputGroupsProps> = ({
  name,
  title,
  children: InputsBlock,
  groupObject,
  deleteModal,
  addButtonName,
  viewOnly,
  titleProps,
  wrapped,
}) => {
  const [isGroupDeleteOpen, { on, off }, removeGroupIndex] = useToggle();

  const { control, watch } = useFormContext();
  const groups = watch(name) as Record<string, string | null | undefined>[];
  const { append, remove } = useFieldArray({ control, name });

  const handleDeleteGroup = () => {
    off();
    remove(removeGroupIndex);
  };

  const handleAddGroup = () => append({ id: nanoid(10), ...groupObject });

  return (
    <>
      <ConfirmDialog
        open={isGroupDeleteOpen}
        onConfirm={handleDeleteGroup}
        onClose={off}
        title={deleteModal.title}
        text={deleteModal.text}
      />

      {groups?.map(({ id }, index) => (
        <GroupWrapper key={id} $wrapped={!!wrapped}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h3"
              sx={{ '&::first-letter': { textTransform: 'uppercase' } }}
              {...titleProps}
            >
              {title} {index + 1}
            </Typography>

            {index !== 0 && (
              <IconButton
                sx={{ p: '1px 2px' }}
                size="small"
                onClick={() => on(index)}
                disabled={viewOnly}
              >
                <TrashIcon />
              </IconButton>
            )}
          </Stack>

          <InputsBlock
            groupNumber={index}
            createName={(fieldName: string) => `${name}[${index}].${fieldName}`}
            viewOnly={viewOnly}
          />
        </GroupWrapper>
      ))}

      {!viewOnly && (
        <Button variant="text" onClick={handleAddGroup}>
          <PlusIcon fill="currentColor" />
          {addButtonName}
        </Button>
      )}
    </>
  );
};

export default ControlledInputGroups;
