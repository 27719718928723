import React, { FC } from 'react';
import FilterSelector from '@components/tables/core/FilterSelector';
import { useProjectFilters } from '@context/ProjectFilters';
import { CiaFilters } from '@context/ProjectFilters/types';
import { CIASettingsCriteriaType } from '@store/services/questionnaire/types';
import { transformArrayToOptions } from '@utils/helpers';

type Props = {
  filterKey: keyof CiaFilters;
  criteriaList: CIASettingsCriteriaType[];
};

const CriteriaFilterList: FC<Props> = ({ filterKey, criteriaList }) => {
  const { ciaFilters, setCiaFilters } = useProjectFilters();

  const handleSetCiaCriteria = (id: string) => (newValues: string[]) => {
    setCiaFilters((prevCia) => ({
      ...prevCia,
      [filterKey]: {
        ...prevCia[filterKey],
        [id]: newValues,
      },
    }));
  };

  return (
    <>
      {criteriaList.map(({ id, name, answers }) => (
        <FilterSelector
          key={filterKey + id}
          title={`By ${name.toLowerCase()}`}
          values={ciaFilters[filterKey][id] ?? []}
          onChange={
            handleSetCiaCriteria(id) as (values: (string | number)[]) => void
          }
          options={transformArrayToOptions(answers)}
          expandByDefault
        />
      ))}
    </>
  );
};

export default CriteriaFilterList;
