import { CiaFilters } from '@context/ProjectFilters/types';
import { BehaviorSubject } from 'rxjs';

type Filters = {
  validationStatus: string[];
  operationalStatuses: string[];
  reviewStatus: string[];
  domains: string[];
  cia: CiaFilters;
};

export const filtersSubject = new BehaviorSubject<Filters>({
  validationStatus: [],
  operationalStatuses: [],
  reviewStatus: [],
  domains: [],
  cia: {
    assurance_level: {},
    coverage: {},
    applicability: {},
  },
});
