import { useLocation, useParams } from 'react-router-dom';
import useProjectTabs from '@hooks/useProjectTabs';

const useProjectId = (includeScenario = false) => {
  const { id = '' } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const currentScenarioId = useProjectTabs().currentId;
  const scenarioMode = pathname.includes('scenario');

  if (includeScenario && scenarioMode) {
    return currentScenarioId ?? '';
  }

  return id;
};

export default useProjectId;
