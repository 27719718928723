import React, { FC } from 'react';
import IsmInput from '@components/EntityDrawers/components/ReferencesInputsGroup/components/IsmInput';
import IsoInput from '@components/EntityDrawers/components/ReferencesInputsGroup/components/IsoInput';
import NistInput from '@components/EntityDrawers/components/ReferencesInputsGroup/components/NistInput';
import QnousInput from '@components/EntityDrawers/components/ReferencesInputsGroup/components/QnousInput';
import { Stack, Typography } from '@mui/material';

const ReferencesInputsGroup: FC = () => {
  return (
    <Stack gap={4}>
      <Typography variant="h4">References</Typography>

      <QnousInput />
      <NistInput />
      <IsoInput />
      <IsmInput />
    </Stack>
  );
};

export default ReferencesInputsGroup;
