import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import CriteriaField from '@components/EntityDrawers/components/CiaInputsGroup/CriteriaField';
import { isShownByDependency } from '@components/EntityDrawers/components/CiaInputsGroup/utils/helpers';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { ISelectOption } from '@constants/entities/ui';
import { Stack, Typography } from '@mui/material';
import { CIASettingsCriteriaType } from '@store/services/questionnaire/types';

type Props = {
  title: string;
  fields: CIASettingsCriteriaType[];
  criteriaKey:
    | CommonKeys.Assurance
    | CommonKeys.Coverage
    | CommonKeys.Applicability;
  onChange?: (criteriaId: string, values: ISelectOption[]) => void;
};

const CriteriaBlock: FC<Props> = ({ title, fields, criteriaKey, onChange }) => {
  const { getValues, setValue } = useFormContext();

  const handleCriteriaChange = (id: string) => (values: ISelectOption[]) => {
    onChange?.(id, values);

    fields.forEach((field) => {
      if (
        field.dependency?.questionId === id &&
        !values.some(({ value }) => value === field.dependency?.answer)
      ) {
        setValue(`${criteriaKey}.${field.id}`, []);
      }
    });

    if (!values.length) {
      setValue(`${criteriaKey}.${id}`, null, { shouldDirty: true });
    }
  };

  return (
    <Stack gap={4}>
      <Typography variant="h4">{title}</Typography>

      {fields.map((field) =>
        isShownByDependency(field.dependency, criteriaKey, getValues) ? (
          <CriteriaField
            key={field.id}
            field={field}
            nameKey={`${criteriaKey}.${field.id}`}
            changeHandler={handleCriteriaChange(field.id)}
          />
        ) : null,
      )}
    </Stack>
  );
};

export default CriteriaBlock;
