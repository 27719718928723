import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GroupWrapper = styled(Stack)<{ $wrapped: boolean }>(
  ({ $wrapped, theme }) => ({
    gap: theme.spacing(4),
    ...($wrapped
      ? {
          padding: theme.spacing(4),
          borderRadius: theme.spacing(3),
          border: `1px solid ${theme.palette.grey[500]}`,
        }
      : {}),
  }),
);
