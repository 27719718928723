import { useState } from 'react';
import { DefaultExportImageType } from '@constants/entities/reports';
import useToggle from '@hooks/useToggle';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image/es/types';

export type GeneratedImageUrl = string | null;

type ExportOptions = {
  toType?: string;
  options?: (el: HTMLElement) => Partial<Options>;
  defaultIsGenerating?: boolean;
};

const useExportHTMLImage = ({
  toType = DefaultExportImageType,
  options,
  defaultIsGenerating = false,
}: ExportOptions = {}) => {
  const [imageUrl, setImageUrl] = useState<GeneratedImageUrl>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isGenerating, { on, off }] = useToggle(defaultIsGenerating);

  const generateImage = async (
    reportViewElement: HTMLElement | null | undefined,
  ) => {
    try {
      on();

      if (!reportViewElement) {
        throw new Error('Export element not found. Please try again.');
      }

      const url = await htmlToImage.toPng(reportViewElement, {
        type: toType,
        quality: 1,
        ...options?.(reportViewElement),
      });

      setImageUrl(url);

      return url;
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      off();
    }
  };

  const resetExportState = () => {
    setIsError(false);
    setImageUrl(null);

    return defaultIsGenerating ? on() : off();
  };

  return { generateImage, imageUrl, isGenerating, isError, resetExportState };
};

export default useExportHTMLImage;
