import { ChangeEvent } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Node } from 'reactflow';
import { ControlledInputGroupsProps } from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups';
import { CanvasEdge, CanvasNode } from '@components/MainStage/types';
import {
  EdgesTypes,
  NodesTypes,
  nodeTypesByMode,
  parentNodeTypes,
} from '@constants/canvas/general';
import { baseLayerNodesByMode } from '@constants/canvas/layerNodes';
import {
  ChangeInitiativesSubLayerTypes,
  Modes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { ISelectOption, WideDomainKey } from '@constants/entities/ui';
import { QmsRoles, UserRoles } from '@constants/entities/user';
import { IndexKey } from '@constants/forms/common';
import { DomainItem } from '@constants/forms/scope-settings';
import { Image, OmitId } from '@constants/types';
import {
  DefaultError,
  NotDigits,
  RequiredErrorSingular,
  YupString,
} from '@constants/validation';
import { nanoid } from '@reduxjs/toolkit';
import { EdgeDTO, NodeDTO, NodeDTOTypes } from '@store/services/nodes/types';
import { IDomain } from '@store/services/projects/types';
import {
  CIAAnswers,
  CIASettingsType,
} from '@store/services/questionnaire/types';
import { Field, FieldTypes } from '@views/Questionnaire/constants/types';
import querystring from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

export function calculatePaginationString(
  currentPage: number,
  itemsPerPage: number,
  totalItems: number,
): string {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return `${startItem}-${endItem} from ${totalItems}`;
}

export const parseInitials = (str: string | undefined, keepCount = 2) => {
  return (
    str
      ?.split(' ')
      .map((s) => s[0])
      .slice(0, keepCount)
      .join('') || ''
  );
};

export const parseErrorResponse = (err: any, def = DefaultError) => {
  if (err.message) return err.message;

  const { data } = err;

  return Array.isArray(data?.message) && data?.message.length > 0
    ? data?.message[0]
    : data?.message || def;
};

export const makeChangeValidator =
  (
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    maxEndSpacesCount = 0,
  ) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const isFirstSpace = value?.[0] === ' ';

    const trailingSpacesCount = value.length - value.trimEnd().length;

    const isTrailingSpacesWithinLimit =
      trailingSpacesCount <= maxEndSpacesCount;

    if (!isFirstSpace && isTrailingSpacesWithinLimit) {
      onChange(e);
    }
  };

export const copyNode = (node: Node | undefined) => {
  if (node) {
    return JSON.parse(JSON.stringify(node));
  }

  return null;
};

export const findMaxCoordinates = (nodes: Node[]) => {
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  nodes.forEach((node) => {
    if (node.positionAbsolute) {
      const { x, y } = node.positionAbsolute;

      if (x > maxX) {
        maxX = x;
      }

      if (y > maxY) {
        maxY = y;
      }
    }
  });

  return { x: maxX, y: maxY };
};

export const formatNodeDTOToCanvasNode = (node: NodeDTO): CanvasNode => ({
  id: node.id,
  type: NodesTypes.CustomNode,
  data: {
    dto: {
      label: node.name,
      ...node,
    },
    canvas: {},
  },
  position: {
    x: node.x,
    y: node.y,
  },
  parentNode: parentNodeTypes[node.type] as SubLayerTypes,
  extent: 'parent',
});

export const formatNodesForCanvas = (
  nodesDTO: NodeDTO[] | undefined,
  mode: Modes,
): CanvasNode[] => {
  const baseLayerNodes = baseLayerNodesByMode[mode];
  if (nodesDTO) {
    const formattedNodes: CanvasNode[] = nodesDTO.map(
      formatNodeDTOToCanvasNode,
    );

    const filterNodesByMode = formattedNodes.filter((node: Node) => {
      return nodeTypesByMode[mode].includes(node.parentNode as never);
    });

    return [...baseLayerNodes, ...filterNodesByMode];
  }
  return [...baseLayerNodes];
};

export const formatEdgeDTOToCanvasEdge = (edge: EdgeDTO): CanvasEdge => ({
  id: edge.id,
  type: EdgesTypes.CustomEdge,
  source: edge.parent_id,
  target: edge.child_id,
  data: { dto: edge, canvas: {} },
});

export const formatEdgesForCanvas = (edgesDTO: EdgeDTO[] | undefined) => {
  if (edgesDTO) {
    const formattedEdges = edgesDTO.map(formatEdgeDTOToCanvasEdge);
    return formattedEdges;
  }
  return [];
};

export const getInputGroupsProps = (
  entityName: string,
): Pick<
  ControlledInputGroupsProps,
  'title' | 'addButtonName' | 'deleteModal'
> => ({
  title: entityName,
  addButtonName: `Add ${entityName}`,
  deleteModal: {
    title: `Delete ${entityName}`,
    text:
      `Are you sure that you want to delete the ${entityName} from the entity metadata?` +
      '\nThis action cannot be undone.',
  },
});

export const getNodeTypeTypeByForSubLayer = (subLayerTypeId: SubLayerTypes) => {
  if (subLayerTypeId === ChangeInitiativesSubLayerTypes.RemediationAction) {
    return NodeDTOTypes.Initiatives;
  }

  return Object.entries(parentNodeTypes).find(
    ([, subLayerId]) => subLayerId === subLayerTypeId,
  )![0]! as NodeDTOTypes;
};

export const emptyToNull = <T>(obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const isString = typeof value === 'string';
    const isEmptyString = isString && value.trim() === '';

    acc[key as keyof T] = isEmptyString ? null : value;

    return acc;
  }, {} as T);
};

export const filterEmptyAndOmitId = <T extends { id: string } = any>(
  array: Array<T>,
  ignoreKeyValueMap: Record<string, any> = {},
): Omit<T, 'id'>[] => {
  return array.reduce((acc: Omit<T, 'id'>[], obj) => {
    const { id, ...rest } = obj;

    const filledKeys = Object.entries(rest).filter(([key, value]) => {
      const isValueFilled = value?.toString().trim() !== '';
      const isNotIgnored = ignoreKeyValueMap[key] !== value;

      return isValueFilled && isNotIgnored;
    });

    if (filledKeys.length) {
      acc.push(Object.fromEntries(filledKeys) as OmitId<T>);
    }

    return acc;
  }, []);
};

export const getEntitiesArray = <T extends { id: string }, I extends {} = any>(
  array: I[],
  defObject: OmitId<T>,
) =>
  array?.length
    ? (array.map((e) => withId(e)) as unknown as T[])
    : [{ id: nanoid(10), ...defObject }];

export type Item = Record<string, any> & { id: string };

type UpdateItemsInArrayOptions = {
  allItems: Item[];
  updatedItems?: Item[];
  removeIds?: string[];
  pipeNewItem?: (newItem: Item, currentItem?: Item) => Item;
};

export const updateItemsInArray = ({
  allItems,
  updatedItems,
  removeIds,
  pipeNewItem,
}: UpdateItemsInArrayOptions) => {
  const currentItemsMap = new Map(allItems.map((item) => [item.id, item]));

  removeIds?.forEach((id) => currentItemsMap.delete(id));

  updatedItems?.forEach((updatedItem) => {
    const currentItem = currentItemsMap.get(updatedItem.id);

    currentItemsMap.set(
      updatedItem.id,
      pipeNewItem ? pipeNewItem(updatedItem, currentItem) : updatedItem,
    );
  });

  return Array.from(currentItemsMap.values());
};

export const cutNumberPeriods = (number: number, count = 2): string => {
  if (Number.isInteger(number)) {
    return number.toString();
  }

  return parseFloat(number.toFixed(count)).toString();
};

export const calculateMaxTabsCount = (totalWidth: number, maxCount = 10) => {
  const TabsCount = Math.floor(totalWidth / 48);
  return Math.min(TabsCount, maxCount);
};

export function formatFileSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return '0 Bytes';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${Math.round(100 * (bytes / 1024 ** i)) / 100} ${sizes[i]}`;
}

// getPercentage(2, 3) => 66.7
export function getPercentage(value: number, total: number) {
  if (total === 0) {
    // To avoid division by zero
    return 0;
  }

  return +((value / total) * 100).toFixed(1);
}

// getFormattedPercentage(2, 3) => "66.7%"
export function getFormattedPercentage(value: number, total: number): string {
  const percentage = getPercentage(value, total);

  // Check if the percentage is an integer
  const roundedPercentage =
    percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);

  return `${roundedPercentage}%`;
}

export const quantityStr = (
  count: number,
  single: string,
  many: string,
): string => {
  return count === 1 ? single : many;
};

export const omitEmptyKeys = (keyValues: Record<string, any>) => {
  return Object.entries(keyValues).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }

      return acc;
    },

    {} as Record<string, any>,
  );
};

export const omitCertainEmptyKeys = (
  keyValues: Record<string, any>,
  keys: (keyof typeof keyValues)[],
) => {
  return keys.reduce(
    (acc, key) => {
      const value = keyValues[key];

      if (value) {
        acc[key] = value;
      }

      return acc;
    },
    {} as Record<string, any>,
  );
};

export const omitKeys = (
  keyValues: Record<string, any>,
  omitkeys: (keyof typeof keyValues)[],
) => {
  return Object.entries(keyValues).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(!omitkeys.includes(key) ? { [key]: value } : {}),
    }),
    {} as Record<string, any>,
  );
};

/**
 * Format a number into a currency string.
 * @example
 * formatCurrency(100234); // Returns '$100,234.00'
 */
export function formatCurrency(amount: number, currency = 'USD'): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(amount);
}

/**
 * Format a number into a compact representation with abbreviations.
 * Examples: formatNumber(160000) => "160K", formatNumber(4500000) => "4.5M"
 */
export function formatNumber(value: number, binary: boolean = false): string {
  if (value === 0) return '0';

  const base = binary ? 1024 : 1000; // Toggle between binary and decimal bases
  const abbreviations = ['', 'K', 'M', 'B', 'T']; // Keep consistent units

  // Calculate the index and value based on the chosen base
  const index = Math.floor(Math.log(value) / Math.log(base));
  const scaledValue = value / base ** index;

  // Format the value to one decimal place if necessary
  const formattedValue =
    scaledValue % 1 === 0 ? scaledValue.toFixed(0) : scaledValue.toFixed(1);

  return formattedValue + abbreviations[index];
}
type Options<T> = {
  label?: string | keyof T;
  value?: string | keyof T;
};

export const transformArrayToOptions = <T = string>(
  array: T[],
  { value, label }: Options<T> = {},
): ISelectOption[] => {
  return array.map((item) => ({
    label: label ? (item as any)[label] : item,
    value: value ? (item as any)[value] : item,
  }));
};
export const withId = <T>(
  obj: T,
  prefix: string | number = '',
): T & { id: string } => {
  return { ...obj, id: prefix + uuidv4() };
};

export const transformValueToDomainId = (value?: string, forUrl?: boolean) => {
  if (value === WideDomainKey) {
    if (forUrl) {
      return 'null';
    }

    return null;
  }

  return value;
};

export const getDomainIdValue = (id?: string | null) => {
  return id ?? WideDomainKey;
};

export const isQmsRole = (role: UserRoles) => QmsRoles.includes(role);

export const createParamsString = (
  params: Record<string, any>,
  options?: querystring.StringifyOptions | undefined,
) => {
  return querystring.stringify(params, { arrayFormat: 'bracket', ...options });
};

export const changeWithNumber =
  (blockZero = false) =>
  (onChange: any) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const digitsStr = value.trim().replace(NotDigits, '');

      if (blockZero && digitsStr !== '' && Number(digitsStr) === 0) return;

      onChange(digitsStr);
    };
  };

const isFile = (obj: any): obj is File => {
  return obj instanceof File;
};

export const getImageURL = (image: Image): string => {
  if (isFile(image)) {
    return URL.createObjectURL(image);
  }

  if (typeof image === 'string') {
    return image;
  }

  return '';
};

export const isValidFields = <T extends FieldValues = FieldValues>({
  keysToCheck,
  schemaObject,
  watch,
}: {
  schemaObject: Record<keyof T, any>;
  keysToCheck: Path<T>[];
  watch: UseFormReturn<T>['watch'];
}) => {
  return yup
    .object()
    .shape(schemaObject)
    .isValidSync(
      transformToObject(
        keysToCheck.reduce(
          (acc, field) => ({ ...acc, [field]: watch(field) }),
          {},
        ),
      ),
      { abortEarly: false },
    );
};

interface DataObject {
  [key: string]: any;
}

export const transformToObject = (data: DataObject): DataObject => {
  const result: DataObject = {};

  Object.entries(data).forEach(([key, value]) => {
    const [topLevelKey, nestedKey] = key.split('.', 2);

    if (nestedKey) {
      if (!result[topLevelKey]) result[topLevelKey] = {};
      result[topLevelKey][nestedKey] = value;
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    ) {
      result[key] = transformToObject(value);
    } else {
      result[key] = value;
    }
  });

  return result;
};

export const checkNestedField = (obj: DataObject, path: string): boolean => {
  return Boolean(getValueByPath(obj, path));
};

export const getValueByPath = <T>(
  obj: DataObject | undefined,
  path: string,
): T | undefined => {
  const keys = path.split('.');

  const check = (
    obj: DataObject | undefined,
    keys: string[],
  ): T | undefined => {
    if (keys.length === 0) {
      return obj as T | undefined;
    }

    const key = keys.shift();

    if (key && obj && key in obj) {
      return check(obj[key], keys);
    }

    return undefined;
  };

  return check(obj, keys);
};

export const withIndex = <T extends string>(
  str: string,
  index: number | number[],
): T => {
  if (Array.isArray(index)) {
    return index.reduce((acc, i) => acc.replace(IndexKey, `${i}`), str) as T;
  }

  return str.replace(IndexKey, `${index}`) as T;
};

/*
 * "Some Enterprise" => "SE"
 * "Some Enterprise Name" => "SE"
 * "Some" => "S"
 * "Some name" => "Sn"
 */

export const parseAbbr = (str: string | undefined, start = 0, end = 2) =>
  str
    ?.split(' ')
    .map((word) => word.charAt(0))
    .slice(start, end)
    .join('') ?? '';

export const parseDomains = (data: IDomain[]): DomainItem[] => {
  const treeMap: Record<string, DomainItem> = {};

  const sortedDomains = data.toSorted(
    (a: IDomain, b: IDomain) => a.num - b.num,
  ) as IDomain[];

  sortedDomains.forEach(({ id, name, authority }) => {
    treeMap[id] = { id, name, authority, subDomains: [] };
  });

  return sortedDomains.reduce((tree, node) => {
    if (node.parentId && treeMap[node.parentId] && node.id !== node.parentId) {
      treeMap[node.parentId].subDomains?.push(treeMap[node.id]);
    } else {
      return tree.concat(treeMap[node.id]);
    }

    return tree;
  }, [] as DomainItem[]);
};

export const trimLower = (str?: string) => {
  return str?.trim().toLowerCase() ?? '';
};

type FindDuplicatesParams<T> = {
  array: T[];
  key: keyof T;
  value: string | undefined;
  currentId?: string;
};

export const hasDuplicates = <T extends { id: string }>({
  array,
  value,
  key,
  currentId,
}: FindDuplicatesParams<T>) =>
  array.some(
    (org) =>
      trimLower(org[key]?.toString()) === trimLower(value) &&
      org.id !== currentId,
  );

export const generateArray = (start: number, end: number): number[] => {
  return Array.from({ length: end - start + 1 }, (_, index) => index + start);
};

export const insert = <T>(array: T[], index: number, ...items: T[]): T[] => {
  return [...array.slice(0, index), ...items, ...array.slice(index)];
};

interface RangeMap {
  [key: string]: [number, number];
}

export const findKeyInRange = <T>(
  input: number,
  rangeMap: RangeMap,
): T | undefined => {
  return Object.entries(rangeMap).find(
    ([_, [min, max]]) => input >= min && input <= max,
  )?.[0] as T | undefined;
};

export const downloadFile = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

/*
 * This function is used to get the size of the file
 *
 * const base64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAABkCAYAAABw4pVUAAACkUlEQVRIDc2Vz0vDUBiFv4f7B
 * const size = getTextFileSize(base64);
 * console.log(size); // 1024
 */
export const getTextFileSize = (str: string | null | undefined) => {
  if (!str?.trim().length) return 0;

  const utf8Bytes = new TextEncoder().encode(str);
  return utf8Bytes.length;
};

export const fixNumber = (value: string | number, digits = 2) => {
  if (Number(value) % 1 === 0) return value;

  return Number(value).toFixed(digits);
};

/*
 * Sorts string alphabetically first, followed by numerical, and then special characters
 * sorting is case insensitive
 */
export const sortStringCb = (a: string, b: string) => {
  function getCharCategory(char: string) {
    if (/[a-zA-Z]/.test(char)) return 1;
    if (/\d/.test(char)) return 2;
    return 3;
  }

  const length = Math.max(a.length, b.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const charA = a[i] || '';
    const charB = b[i] || '';

    const categoryA = getCharCategory(charA);
    const categoryB = getCharCategory(charB);

    if (categoryA !== categoryB) {
      return categoryA - categoryB;
    }

    const comparison = charA.localeCompare(charB, undefined, {
      sensitivity: 'base',
    });
    if (comparison !== 0) {
      return comparison;
    }
  }

  return 0;
};

export function setNestedValue(obj: any, path: string[], value: any) {
  let current = obj;

  path.forEach((key, index) => {
    if (index === path.length - 1) {
      current[key] = value;
    } else {
      current[key] = current[key] || {};
      current = current[key];
    }
  });
}

type Structure = {
  [key: string]: Structure | { type: string; required: boolean };
};

export function generateNestedStructure(fields: Field[]): Structure {
  const result: any = {};

  fields.forEach(({ name, type, required }) => {
    const path = name.split('.');
    let current = result;

    path.forEach((key, index) => {
      if (!current[key]) {
        current[key] =
          index === path.length - 1
            ? { type, required } // Leaf node
            : {}; // Nested object
      }

      current = current[key];
    });
  });

  return result;
}

export function generateYupSchema(structure: Structure) {
  const schema: any = {};

  Object.entries(structure).forEach(([key, value]) => {
    if (value.type) {
      if (value.type === FieldTypes.SelectMultiple) {
        schema[key] = yup
          .array()
          .of(yup.string())
          .min(value.required ? 1 : 0, RequiredErrorSingular); // Apply min if required
      } else {
        schema[key] = YupString();

        if (value.required) {
          schema[key] = schema[key].required(RequiredErrorSingular); // Conditionally apply required
        }
      }
    } else {
      schema[key] = yup.object(generateYupSchema(value as any));
    }
  });

  return schema;
}

export const questionnaireResultToCiaFilters = (
  originCriteria: CIASettingsType,
  input: CIAAnswers,
): Record<string, Record<string, string[]>> => {
  const { assurance_level, coverage, applicability } = input;

  return {
    assurance_level: Object.fromEntries(
      Object.entries(assurance_level).map(([id, { answer }]) => {
        // Find the corresponding originAssuranceCriteria
        const originAssuranceCriteria =
          originCriteria.assurance_level.criterias.find(
            (originAssuranceCriteria) => originAssuranceCriteria.id === id,
          );

        if (originAssuranceCriteria?.answers) {
          // Find the index of the current answer
          const answerIndex = originAssuranceCriteria.answers.findIndex(
            (a) => a === answer,
          );

          // If the answer is found, slice the array to include all items from the answer onwards
          if (answerIndex !== -1) {
            const answersFromIndex =
              originAssuranceCriteria.answers.slice(answerIndex);

            return [id, answersFromIndex]; // Include the answer and the subsequent answers
          }
        }

        return [id, [answer]]; // Fallback if no answer found or no subsequent answers
      }),
    ),

    ...Object.fromEntries(
      Object.entries({ coverage, applicability }).map(([key, value]) => [
        key,
        Object.fromEntries(
          Object.entries(value).map(([id, { answer }]) => [id, [answer]]),
        ),
      ]),
    ),
  };
};
