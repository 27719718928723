import React, { FC } from 'react';
import ControlAssurance from '@components/EntityDrawers/components/ControlAssurance';
import { AssuranceControlObject } from '@components/EntityDrawers/constants/empty-objects';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { PhysicalSubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useDebounce } from '@hooks/useDebounce';
import { getInputGroupsProps } from '@utils/helpers';

const titles = {
  [PhysicalSubLayerTypes.Functions]: 'Control reference',
  [PhysicalSubLayerTypes.Capabilities]: 'Control objective',
} as const;

const ControlInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => {
  const { subLayer } = useDrawerContext();

  return (
    <>
      <ControlledTextField
        name={createName('name')}
        placeholder={titles[subLayer as PhysicalSubLayerTypes]}
        disabled={viewOnly}
        multiline
        sx={{ mt: -3 }}
      />

      <ControlAssurance
        criteriaName={createName('criterias')}
        checkName={createName('same_as_entity_assurance')}
      />
    </>
  );
};

type Props = { name: string };

const ControlGroup: FC<Props> = ({ name }) => {
  const { viewOnly } = useProject();
  const { subLayer } = useDrawerContext();
  const subLayerId = useDebounce(subLayer, 1000);

  return (
    <ControlledInputGroups
      name={name}
      viewOnly={viewOnly}
      groupObject={AssuranceControlObject}
      titleProps={{ variant: 'h5' }}
      wrapped
      {...getInputGroupsProps(
        titles[subLayerId as PhysicalSubLayerTypes]?.toLowerCase(),
      )}
    >
      {ControlInputs}
    </ControlledInputGroups>
  );
};

export default ControlGroup;
