import { RolesPermissionsOrElements } from '@constants/entities/user';
import useRolesCheck from '@hooks/useRolesCheck';
import useScenarioMode from '@hooks/useScenarioMode';

const useCanEditModel = () => {
  const { scenarioMode } = useScenarioMode();

  const canEditModel = useRolesCheck(RolesPermissionsOrElements.EditMainModel);
  const canEditScenarios = useRolesCheck(
    RolesPermissionsOrElements.EditScenarios,
  );

  return scenarioMode ? canEditScenarios : canEditModel;
};

export default useCanEditModel;
