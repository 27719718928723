import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InfoTooltip from '@components/Inputs/InfoTooltip';
import Label from '@components/Inputs/Label';
import { ISelectOption } from '@constants/entities/ui';
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from '@mui/material';

export type ControlledRadioGroupProps = Pick<RadioGroupProps, 'row' | 'sx'> & {
  required?: boolean;
  label?: string;
  options: ISelectOption[];
  name: string;
  disabledAll?: boolean;
  triggerFields?: string[];
  handleChange?: (value: string) => boolean | undefined | void;
  withoutRadioMargin?: boolean;
  tooltip?: string;
};

const ControlledRadioGroup: FC<ControlledRadioGroupProps> = ({
  options,
  label,
  required,
  name,
  disabledAll,
  triggerFields = [],
  handleChange,
  withoutRadioMargin,
  tooltip,
  ...radioGroupProps
}) => {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Stack gap={1.5} sx={{ width: '100%' }}>
          {label && (
            <Label required={required}>
              {label}
              {tooltip && <InfoTooltip text={tooltip} />}
            </Label>
          )}

          <RadioGroup
            {...field}
            onChange={(e) => {
              const preventChange = handleChange?.(e.target.value);

              if (preventChange !== true) onChange(e);

              trigger([name, ...triggerFields]);
            }}
            {...radioGroupProps}
          >
            {options.map(({ value, label, disabled }) => (
              <FormControlLabel
                key={value + label}
                value={value}
                label={label}
                sx={withoutRadioMargin ? { m: 0 } : {}}
                control={
                  <Radio
                    disabled={disabled || disabledAll}
                    checked={value === field.value}
                  />
                }
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          )}
        </Stack>
      )}
    />
  );
};

export default ControlledRadioGroup;
