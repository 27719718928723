import React from 'react';
import NotoSansBold from '@assets/fonts/NotoSans/NotoSans-Bold.ttf';
import NotoSansMedium from '@assets/fonts/NotoSans/NotoSans-Medium.ttf';
import NotoSansRegular from '@assets/fonts/NotoSans/NotoSans-Regular.ttf';
import NotoSansSemiBold from '@assets/fonts/NotoSans/NotoSans-SemiBold.ttf';
import { createTheme } from '@mui/material/styles';
import {
  CheckBoxCheckedIcon,
  CheckBoxIcon,
  CloseCircleIcon,
  CloseIcon,
  ErrorIcon,
  InfoCircleIcon,
  RadioCircleCheckedIcon,
  RadioCircleIcon,
  SuccessTickIcon,
  WarningIcon,
} from '@utils/iconsDefs';

export const themePalette = {
  primary: { main: '#1dd899' },
  white: '#ffffff',

  grey: {
    1000: '#3c3c3b',
    900: '#636362',
    800: '#7c7c7c',
    700: '#9c9c9b',
    600: '#c7c7c7',
    500: '#ececeb',
    400: '#fbfbfa',
  },

  green: {
    900: '#4AE0AD',
    800: '#68E5BB',
    700: '#97EDD0',
    600: '#B9F3DF',
    550: '#DBFFF3',
    500: '#E7F5EF',
  },

  primaryRelated: {
    1000: '#206377',
    900: '#118090',
    800: '#0E68A5',
    700: '#1794BC',
    600: '#0DAEAE',
    500: '#16AAD1',
  },

  red: {
    1000: '#F21443',
    900: '#F54369',
    800: '#F66281',
    700: '#FBB6C5',
    600: '#FEE8EC',
  },

  blue: {
    1100: '#2D598A',
    1000: '#1570EF',
    500: '#EBF0F9',
  },

  yellow: {
    1000: '#E38100',
    700: '#FFE5A6',
    600: '#FCEBC1',
  },

  system: {
    error: '#F04438',
    alert: '#E38100',
    success: '#039855',
    info: '#1570EF',
  },
};

export const chipVariantColorMap = {
  red: themePalette.red[1000],
  grey: themePalette.grey[900],
  yellow: themePalette.yellow[1000],
  green: themePalette.system.success,
};

export const typography = {
  allVariants: {
    color: themePalette.grey[1000],
  },
  fontFamily: ['Noto Sans', 'Roboto', 'sans-serif'].join(','),
  h1: {
    fontSize: 32,
    lineHeight: '38px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  h2: {
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  h3: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  h4: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  h5: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  body1: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 400,
  },
  body2: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: 0,
    fontWeight: 600,
  },
};

export const muiTheme = createTheme({
  spacing: 4,
  palette: themePalette,

  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Noto sans';
          font-style: normal;
          src: url(${NotoSansBold}) format('truetype');
          font-weight: 700;
        }
        @font-face {
          font-family: 'Noto sans';
          font-style: normal;
          src: url(${NotoSansSemiBold}) format('truetype');
          font-weight: 600;
        }
         @font-face {
          font-family: 'Noto sans';
          font-style: normal;
          src: url(${NotoSansRegular}) format('truetype');
          font-weight: 400;
        }
         @font-face {
          font-family: 'Noto sans';
          font-style: normal;
          src: url(${NotoSansMedium}) format('truetype');
          font-weight: 500;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-text-fill-color: inherit;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        margin: 2px
      }

      ::-webkit-scrollbar-track {
        background: ${themePalette.grey[500]};
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${themePalette.grey[700]};
        border-radius: 10px;
        border: 0px solid;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: gray;
      }

      .react-flow__attribution {
        display: none;
      }
      
      body {
        overflow-y: hidden;
      }

      input[type="password"]::-ms-reveal {
        display: none;
      }
      `,
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input::placeholder': {
            WebkitTextFillColor: themePalette.grey[800],
            fontSize: '14px',
            lineHeight: '24px',
            fontFamily: 'Noto Sans',
            opacity: 1,
          },
          '& fieldset': {
            borderColor: themePalette.grey[700],
          },
          '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: themePalette.grey[700],
            },
            '&.Mui-focused fieldset': {
              borderColor: themePalette.primary.main,
            },
            '&.Mui-disabled': {
              '& input::placeholder': {
                WebkitTextFillColor: themePalette.grey[700],
              },
              '& fieldset': {
                border: `1px solid ${themePalette.grey[600]}`,
                boxShadow: 'none',
              },
            },
            '&.Mui-error fieldset': {
              border: `1px solid ${themePalette.system.error}`,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckBoxIcon />,
        checkedIcon: <CheckBoxCheckedIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          '& input': { zIndex: 5 },

          '&.Mui-disabled svg': {
            color: `${themePalette.grey[500]} !important`,
          },

          '&:hover svg': {
            fill: themePalette.grey[400],
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ...typography.h4,

          '&.Mui-disabled': {
            color: 'white',
            backgroundColor: themePalette.green[700],
            pointerEvents: 'none',

            '& .MuiTypography-h3': {
              color: 'white !important',
            },
            '& .MuiTypography-h4': {
              color: 'white',
            },
          },
          '&.Mui-focusVisible': {
            color: themePalette.white,
            backgroundColor: themePalette.green[700],
            boxShadow: 'none',
          },

          '&.MuiButton-sizeMedium': {
            height: '40px',
            padding: '8px 14px',
          },

          '&.MuiButton-sizeLarge': {
            height: '48px',
            padding: '12px 20px',
          },
          color: themePalette.grey[1000],
          textTransform: 'none',
          borderRadius: '12px',
          boxShadow: 'none',
          '&.MuiButton-containedPrimary:not(:disabled):hover': {
            backgroundColor: themePalette.green[900],
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },

        contained: {
          '& .MuiButton-startIcon': {
            marginLeft: 0,
          },
        },

        outlined: {
          backgroundColor: themePalette.white,
          border: `1px solid ${themePalette.grey[700]}`,
          '&.Mui-disabled': {
            backgroundColor: themePalette.white,
            color: themePalette.grey[700],
            border: `1px solid ${themePalette.grey[500]}`,
          },
          '&:hover': {
            backgroundColor: themePalette.grey[400],
            borderColor: themePalette.grey[800],
          },
        },

        outlinedError: {
          '&, &:hover': {
            border: `1px solid ${themePalette.system.error}`,
            color: `${themePalette.system.error}`,
          },
        },

        textSecondary: {
          color: themePalette.grey[1000],

          '&:not(:disabled):hover': {
            backgroundColor: 'transparent',
            color: themePalette.grey[900],
          },

          '&.Mui-disabled': {
            color: themePalette.grey[700],
            backgroundColor: 'transparent',
          },
        },

        text: {
          borderRadius: 0,
          width: 'fit-content',
          padding: '8px 0px',
          color: themePalette.primary.main,

          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',

          gap: 8,

          '&:not(:disabled):hover': {
            backgroundColor: 'transparent',
            color: themePalette.green[900],
          },

          '&.Mui-disabled': {
            color: themePalette.green[700],
            backgroundColor: 'transparent',
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'under-text',
          },
          style: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            padding: '8px 14px',
            textDecoration: 'underline',
            color: themePalette.grey[1000],

            '&:not(:disabled):hover': {
              backgroundColor: 'transparent',
              color: themePalette.grey[900],
              textDecoration: 'underline',
            },

            '&:focus': {
              color: themePalette.grey[600],
            },

            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              color: themePalette.grey[600],
            },

            '&:has(> .icon)': {
              gap: 8,
              paddingLeft: 0,
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'auto',

            '& .MuiTypography-h3': {
              color: themePalette.grey[700],
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.MuiButton-sizeLarge': {
            borderTop: `1px solid ${themePalette.grey[600]}`,
          },
        },
      },

      variants: [
        {
          props: { dark: true },
          style: {
            borderColor: themePalette.grey[900],
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconSize: 'small',
        components: {
          CloseIcon: () => <CloseIcon fill={themePalette.grey[900]} />,
        },
        iconMapping: {
          info: <InfoCircleIcon />,
          warning: <WarningIcon />,
          success: <SuccessTickIcon />,
          error: <ErrorIcon />,
        },
      },
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: themePalette.white,
          padding: '12px 38px 12px 12px',
          borderRadius: '12px',
          color: themePalette.grey[1000],
          alignItems: 'flex-start',
        },
        icon: {
          display: 'flex',
          alignItems: 'center',
          marginRight: '8px',
          padding: 0,
          opacity: 1,
        },
        message: {
          padding: '4px 22px 0 0',
          marginRight: '8px',
        },
        action: {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          margin: 0,
          position: 'absolute',
          right: 12,
          top: '16px',
          transform: 'none',
        },

        standard: {
          boxShadow: 'none',
          padding: 12,

          '& .MuiAlert-icon': {
            padding: 0,
          },
        },

        standardInfo: {
          backgroundColor: themePalette.grey[400],
        },

        standardWarning: {
          color: themePalette.system.alert,
          backgroundColor: themePalette.yellow[600],
        },

        filledError: {
          backgroundColor: themePalette.red[600],
          color: themePalette.red[1000],
          padding: '12px 38px 12px 12px',
          borderRadius: '12px',
          boxShadow: 'none',

          '& .MuiAlert-icon': {
            padding: 0,

            '& svg': {
              fill: themePalette.red[1000],
            },
          },
        },
      },

      variants: [
        {
          props: { verticalCenter: true },
          style: {
            alignItems: 'center',

            '& .MuiAlert-action': {
              top: '50%',
              transform: 'translateY(-50%)',
            },
          },
        },
        {
          props: { withShadow: true },
          style: { boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)' },
        },

        {
          props: { iconSize: 'medium' },
          style: {
            '& .MuiAlert-icon svg': {
              width: 32,
              height: 32,
            },
          },
        },
        {
          props: { iconSize: 'small' },
          style: {
            '& .MuiAlert-icon svg': {
              width: 24,
              height: 24,
            },
          },
        },
      ],
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          width: '100%',

          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: 510,
          },

          '& .MuiAlert-message .MuiTypography-root': {
            whiteSpace: 'pre-wrap',
          },
        },
      },
      defaultProps: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          animationDuration: '2s',
        },
        circle: {
          animationDuration: '2s',
          animationTimingFunction: 'ease-out',
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: 'calc(100% - 4px)',
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 100,
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: themePalette.grey[400],
          },
        },
      },
    },

    MuiTableCell: {
      variants: [
        {
          props: { variant: 'headWithBorder' },
          style: {
            backgroundColor: themePalette.white,
            color: themePalette.grey[800],
            borderBottom: `1px solid ${themePalette.grey[500]}`,
            lineHeight: '24px',
          },
        },
      ],

      styleOverrides: {
        root: {
          padding: '8px 24px 8px 12px',
          lineHeight: '16px',
        },

        head: {
          color: themePalette.grey[800],
          backgroundColor: themePalette.grey[400],
          borderBottom: 'none',

          '&:first-of-type': {
            borderRadius: '12px 0 0 12px',
          },

          '&:last-of-type': {
            borderRadius: '0 12px 12px 0',
          },
        },

        body: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',

          borderColor: themePalette.grey[500],
          height: '60px',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '4px 0px',
          borderRadius: '8px',
          background: themePalette.white,
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '8px 12px',
          gap: 8,
          color: themePalette.grey[900],

          '&.Mui-disabled': {
            pointerEvents: 'none',
            cursor: 'default',
          },

          '&.Mui-focusVisible': {
            backgroundColor: `${themePalette.white} !important`,

            '&:hover': {
              backgroundColor: `${themePalette.grey[400]} !important`,
            },
          },

          '&:hover': {
            background: themePalette.grey[400],
            color: themePalette.grey[1000],

            '& .icon-hover': {
              fill: `${themePalette.grey[1000]} !important`,
            },
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          background: themePalette.grey[500],
          color: themePalette.grey[900],
          borderRadius: '12px',
        },

        label: {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '16px',
          display: 'block',
          position: 'relative',
          zIndex: 2,
        },

        sizeMedium: {
          height: '24px',
          padding: '4px 12px',
        },

        labelMedium: {
          padding: 0,
        },
        icon: {
          marginRight: 4,
          marginLeft: 0,
        },
        iconMedium: {
          width: '16px',
          height: '16px',
          minWidth: '16px',
        },
        deleteIcon: {
          minWidth: '16px !important',
        },
        deleteIconSmall: { width: '16px', height: '16px', minWidth: '16px' },
      },

      defaultProps: {
        deleteIcon: <CloseCircleIcon />,
      },
      variants: [
        {
          props: { variant: 'red' },
          style: {
            backgroundColor: themePalette.red[600],
            color: chipVariantColorMap.red,
            '&:hover': {
              backgroundColor: themePalette.red[700],
            },

            '& .MuiChip-deleteIcon': {
              fill: themePalette.red[1000],
            },
          },
        },
        {
          props: { variant: 'grey' },
          style: {
            backgroundColor: themePalette.grey[500],
            color: chipVariantColorMap.grey,
            '&:hover': {
              backgroundColor: themePalette.grey[600],
            },
          },
        },
        {
          props: { variant: 'yellow' },
          style: {
            backgroundColor: themePalette.yellow[600],
            color: chipVariantColorMap.yellow,
            '&:hover': {
              backgroundColor: themePalette.yellow[700],
            },
          },
        },
        {
          props: { variant: 'green' },
          style: {
            backgroundColor: themePalette.green[500],
            color: chipVariantColorMap.green,
            '&:hover': {
              backgroundColor: themePalette.green[600],
            },
          },
        },
      ],
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: '36px',
          height: '36px',
          margin: '0 6px',

          borderColor: themePalette.grey[800],

          '&.Mui-selected': {
            backgroundColor: themePalette.primary.main,
            color: themePalette.grey[1000],
            border: 'none',
          },

          '&:hover': {
            background: themePalette.grey[400],
          },
        },

        rounded: { borderRadius: '8px' },

        ellipsis: {
          minWidth: '20px',
          width: '20px',

          display: 'flex',
          alignItems: 'end',
          justifyContent: 'center',
          margin: 0,
          paddingBottom: 8,
        },

        text: {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
        },

        previousNext: {
          margin: '0px 18px',
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        title: {
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 400,
        },
        subheader: {
          color: themePalette.grey[800],
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 500,
        },
        avatar: {
          marginRight: 8,

          '& .MuiAvatar-root': {
            backgroundColor: themePalette.blue[500],
            color: themePalette.blue[1000],
            textTransform: 'uppercase',
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            background: 'rgba(60, 60, 59, 0.8)',
          },
        },
        paper: {
          boxShadow: 'none',
          borderRadius: 12,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px 24px 12px 24px',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px 24px ',
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
        },
      },
    },

    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 400,
          color: themePalette.grey[900],
          whiteSpace: 'pre-wrap',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
          gap: 16,

          '& > *': {
            flex: 1,
            marginLeft: '0px !important',
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            width: '100%',
            borderRadius: '12px',
            minHeight: '44px',
            height: 'initial',
            padding: '10px 12px',
            color: themePalette.grey[1000],

            '& fieldset': {
              borderColor: themePalette.grey[700],
            },

            '& input': {
              padding: 0,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              marginBottom: 2,
            },
          },

          '&.placeholder .MuiSelect-select': {
            color: themePalette.grey[800],
          },

          '& .MuiSelect-select': {
            padding: 0,
          },

          '& .MuiInputBase-readOnly': {
            pointerEvents: 'none',

            '& input': {
              color: themePalette.grey[700],
            },

            '& fieldset': {
              borderColor: themePalette.grey[500],
            },

            '& .MuiIconButton-root svg': {
              fill: themePalette.grey[700],
            },
          },
        },
      },

      defaultProps: {
        inputProps: {
          autoComplete: 'off',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            color: themePalette.grey[900],

            '&.Mui-selected, &:hover': {
              color: themePalette.grey[1000],
              backgroundColor: 'transparent',
            },

            '&:hover': {
              backgroundColor: `${themePalette.grey[400]} !important`,
            },
          },
        },
        list: {
          padding: 0,
        },
        paper: {
          marginTop: 12,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -9,
          '& .MuiRadio-root': {
            padding: 6,
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-disabled > svg': {
            color: `${themePalette.grey[600]} !important`,
          },

          '&:hover svg': {
            fill: themePalette.grey[400],
          },
        },
      },
      defaultProps: {
        icon: <RadioCircleIcon />,
        checkedIcon: <RadioCircleCheckedIcon />,
        disableRipple: true,
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '4px 0 0',
          color: themePalette.grey[800],
          fontSize: 12,
          lineHeight: '16px',
          letterSpacing: 0,
          fontWeight: 500,
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          background: themePalette.blue[500],
          color: themePalette.blue[1000],
          textTransform: 'uppercase',
        },
      },
    },

    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          backgroundColor: themePalette.blue[500],
          marginLeft: -16,
          width: 40,
          height: 40,
          boxSizing: 'border-box',
          border: '1px solid',
          borderColor: themePalette.white,

          fontSize: 16,
          lineHeight: '24px',
          fontWeight: 600,

          color: themePalette.blue[1000],
          background: themePalette.blue[500],

          '&:first-of-type': {
            order: 1000,
            marginLeft: -16,
          },
          '&:last-of-type': {
            marginLeft: -16,
          },
        },
        root: {
          marginLeft: 16,
          flexDirection: 'row',
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 42,
          height: 24,
        },
        track: {
          borderRadius: 55,
          opacity: 1,
          background: themePalette.grey[600],
        },
        thumb: {
          boxShadow: 'none',
          filter:
            'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: themePalette.white,
          },
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            background: themePalette.primary.main,
          },
          '&.Mui-disabled': {
            color: themePalette.white,
          },

          '&.Mui-disabled+.MuiSwitch-track': {
            background: themePalette.grey[500],
            opacity: 1,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          '&[data-popper-placement="bottom-start"] .MuiTooltip-arrow': {
            left: '15px !important',
            right: 'auto !important',
            transform: 'none !important',
          },
          '&[data-popper-placement="bottom-end"] .MuiTooltip-arrow': {
            right: 15,
            left: 'auto !important',
            transform: 'none !important',
          },
        },

        tooltip: {
          padding: '8px 12px',
          background: themePalette.grey[1000],
          maxWidth: 320,
          borderRadius: 8,
        },

        arrow: {
          color: themePalette.grey[1000],
        },
      },
      defaultProps: {
        arrow: true,
        enterDelay: 1000,
        enterNextDelay: 1000,
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: '1px',
          borderRadius: '12px',
          border: `1px solid ${themePalette.grey[700]}`,
        },
        grouped: {
          '&:not(:first-of-type)': {
            borderRadius: 10,
          },
          '&:first-of-type': {
            borderRadius: 10,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          border: 'none',
          textTransform: 'none',
          flex: '1',
          flexBasis: '142px',
          margin: '0 !important',

          '&.Mui-selected': {
            background: themePalette.grey[500],
            color: themePalette.grey[1000],
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,

          '& svg': {
            transition: 'all 0.4s ease-in-out',
          },
          '&.Mui-disabled': {
            border: `1px solid ${themePalette.grey[500]}`,

            '& svg': {
              fill: themePalette.grey[500],
            },
          },
        },
        sizeLarge: { width: 36, height: 36, padding: 8 },
        sizeMedium: { width: 32, height: 32 },
        sizeSmall: { width: 24, height: 24 },
      },
      variants: [
        {
          props: { size: 'xLarge' },
          style: {
            width: 40,
            height: 40,
            padding: 10,
          },
        },
        {
          props: { size: 'xxLarge' },
          style: {
            width: 48,
            height: 48,
            padding: 10,
          },
        },
        {
          props: { size: 'initial' },
          style: {
            width: 'initial',
            height: 'initial',
            padding: 0,
          },
        },
        {
          props: { variant: 'simple' },
          style: {
            border: `1px solid ${themePalette.grey[700]}`,
          },
        },
        {
          props: { variant: 'shadow' },
          style: {
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          },
        },
      ],
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',

          '&::after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '1px',
            bottom: 0,
            left: 0,
            backgroundColor: themePalette.grey[500],
          },
        },

        indicator: {
          zIndex: 4,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          color: themePalette.grey[900],

          '&.Mui-selected': {
            color: themePalette.grey[1000],
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 10,
          borderRadius: 10,
        },
        bar: {
          borderRadius: 10,
        },
      },

      variants: [
        {
          props: { appearance: 'grey' },
          style: {
            backgroundColor: themePalette.grey[500],
            '& > span': {
              backgroundColor: themePalette.grey[800],
            },
          },
        },

        {
          props: { appearance: 'green' },
          style: {
            backgroundColor: themePalette.green[500],
            '& > span': {
              backgroundColor: themePalette.primary.main,
            },
          },
        },

        {
          props: { appearance: 'red' },
          style: {
            backgroundColor: themePalette.red[600],
            '& > span': {
              backgroundColor: themePalette.red[1000],
            },
          },
        },

        {
          props: { appearance: 'orange' },
          style: {
            backgroundColor: themePalette.yellow[600],
            '& > span': {
              backgroundColor: themePalette.yellow[1000],
            },
          },
        },
      ],
    },

    MuiAutocomplete: {
      defaultProps: {
        clearIcon: (
          <CloseCircleIcon
            fill={themePalette.system.error}
            width={20}
            height={20}
          />
        ),
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            height: 'initial !important',
            minHeight: '44px !important',
          },

          '& .MuiInputBase-input': { padding: '0 !important' },
          '& .MuiChip-root': { margin: '0 !important' },
          '& .MuiAutocomplete-inputRoot': { gap: '8px' },
        },

        option: {
          gap: 8,
          padding: '8px 12px !important',

          '&[aria-selected="true"]': {
            backgroundColor: `${themePalette.white} !important`,
          },
        },

        endAdornment: {
          top: 'unset',
          '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
          },

          '& .MuiIconButton-root[aria-label="Close"] .dropdown': {
            fill: themePalette.grey[1000],
          },

          '& .dropdown': {
            fill: themePalette.grey[900],

            '&.hasValue': {
              fill: themePalette.grey[1000],
            },
          },
        },

        paper: {
          marginTop: 16,
          marginBottom: 16,
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
        },

        listbox: { padding: '4px 0' },
      },
    },

    MuiStep: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: 4,
            ...typography.h5,
          },
        },
      },
    },
  },
});
