import { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { ISelectOption } from '@constants/entities/ui';
import { useToast } from '@context/Toast';
import useProjectId from '@hooks/useProjectId';
import useReferenceMaps from '@hooks/useReferenceMaps';
import { useChangeNodeReferencesMutation } from '@store/services/nodes';
import { NodeReferencesActions } from '@store/services/nodes/types';
import { parseErrorResponse } from '@utils/helpers';

const useChangeReference = () => {
  const projectId = useProjectId();
  const { showToast } = useToast();
  const { watch, setValue } = useFormContext();
  const [changeNodeReference, { isLoading: isChangingReference }] =
    useChangeNodeReferencesMutation();

  const [{ qnousMap, nistMap, isoMap, ismMap }] = useReferenceMaps();

  const handleChangeReference =
    (
      type:
        | CommonKeys.QnousRef
        | CommonKeys.NistRef
        | CommonKeys.IsoRef
        | CommonKeys.IsmRef,
    ) =>
    async (_: SyntheticEvent, newReferences: ISelectOption[]) => {
      const currentReferences = watch(type);

      const revertChanges = () => {
        setValue(type, currentReferences);
      };

      try {
        setValue(type, newReferences);

        const addedReference = newReferences.find(
          (newReference) =>
            !currentReferences.some(
              (currentReference: ISelectOption) =>
                currentReference.value === newReference.value,
            ),
        );

        const removedReference = addedReference
          ? null
          : currentReferences.find(
              (currentReference: ISelectOption) =>
                !newReferences.some(
                  (newReference) =>
                    currentReference.value === newReference.value,
                ),
            );

        const { nist, qnous_ref, iso, ism } = await changeNodeReference({
          qnous_ref:
            watch(CommonKeys.QnousRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          nist:
            watch(CommonKeys.NistRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          iso:
            watch(CommonKeys.IsoRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          ism:
            watch(CommonKeys.IsmRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          type,

          project_id: projectId,

          ...(addedReference
            ? {
                action: NodeReferencesActions.Add,
                value: addedReference.value as string,
              }
            : {
                action: NodeReferencesActions.Rem,
                value: (removedReference?.value as string) ?? '',
              }),
        }).unwrap();

        setValue(
          CommonKeys.QnousRef,
          qnous_ref.map((ref) => ({
            value: ref,
            label: qnousMap.get(ref) ?? ref,
          })),
          { shouldDirty: true },
        );

        setValue(
          CommonKeys.NistRef,
          nist.map((ref) => ({ value: ref, label: nistMap.get(ref) ?? ref })),
          { shouldDirty: true },
        );

        setValue(
          CommonKeys.IsoRef,
          iso.map((ref) => ({ value: ref, label: isoMap.get(ref) ?? ref })),
          { shouldDirty: true },
        );

        setValue(
          CommonKeys.IsmRef,
          ism.map((ref) => ({ value: ref, label: ismMap.get(ref) ?? ref })),
          { shouldDirty: true },
        );
      } catch (error) {
        revertChanges();
        showToast(parseErrorResponse(error), 'error');
      }
    };

  return { handleChangeReference, isChangingReference };
};

export default useChangeReference;
