import { UseFormGetValues } from 'react-hook-form';
import { ISelectOption } from '@constants/entities/ui';
import { CriteriaDependency } from '@store/services/questionnaire/types';

export const isShownByDependency = (
  dependency: CriteriaDependency | undefined,
  criteriaKey: string,
  getValues: UseFormGetValues<any>,
) => {
  if (!dependency) return true;

  const nameKey = `${criteriaKey}.${dependency.questionId}`;
  const selectedDependencyValues = getValues(nameKey);

  return (
    selectedDependencyValues &&
    selectedDependencyValues.some(
      ({ value }: ISelectOption) => value === dependency.answer,
    )
  );
};
