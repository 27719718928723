import React, { createContext, useContext, useMemo } from 'react';
import {
  ProjectFiltersContextProps,
  ProjectFiltersProps,
} from '@context/ProjectFilters/types';
import { useFilters } from '@context/ProjectFilters/useFilters';

const ProjectFiltersContext = createContext<
  ProjectFiltersContextProps | undefined
>(undefined);

const ProjectFiltersProvider: React.FC<ProjectFiltersProps> = ({
  children,
}) => {
  const {
    validationStatusOptions,
    reviewStatusOptions,
    validationStatus,
    reviewStatus,
    setValidationStatus,
    setReviewStatus,
    selectedFilters,
    handleClearAllFilters,
    ciaFilters,
    setCiaFilters,
    operationalStatuses,
    setOperationalStatuses,
  } = useFilters();

  const value = useMemo(
    () => ({
      validationStatusOptions,
      reviewStatusOptions,
      validationStatus,
      reviewStatus,

      setValidationStatus,
      setReviewStatus,

      selectedFilters,
      handleClearAllFilters,

      ciaFilters,
      setCiaFilters,

      operationalStatuses,
      setOperationalStatuses,
    }),
    [
      validationStatusOptions,
      reviewStatusOptions,
      validationStatus,
      reviewStatus,
      selectedFilters,
      ciaFilters,
      setCiaFilters,
      operationalStatuses,
      setOperationalStatuses,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ProjectFiltersContext.Provider value={value}>
      {children}
    </ProjectFiltersContext.Provider>
  );
};

export const useProjectFilters = () => {
  const context = useContext(ProjectFiltersContext);

  if (!context) {
    throw new Error(
      'useProjectFilters must be used within a ProjectDrawerProvider',
    );
  }

  return context;
};

export default ProjectFiltersProvider;
