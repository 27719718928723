import { ISelectOption } from '@constants/entities/ui';
import { useGetQnousRefsQuery } from '@store/services/qnous-ref';
import { IQnousRef } from '@store/services/qnous-ref/types';

const transformRefsToOptions = (data: undefined | IQnousRef[]) => {
  return (
    data?.reduce(
      (acc, { id, name, hidden }) => [
        ...acc,
        ...(hidden ? [] : [{ value: id, label: name }]),
      ],
      [] as ISelectOption[],
    ) ?? []
  );
};

const useQnousRefOptions = (): [
  {
    qnousOptions: ISelectOption[];
    nistOptions: ISelectOption[];
    isoOptions: ISelectOption[];
    ismOptions: ISelectOption[];
  },
  boolean,
] => {
  const { data, isLoading, isFetching } = useGetQnousRefsQuery();
  const { qnous_ref, nist, iso, ism } = data ?? {};

  return [
    {
      qnousOptions: transformRefsToOptions(qnous_ref),
      nistOptions: transformRefsToOptions(nist),
      isoOptions: transformRefsToOptions(iso),
      ismOptions: transformRefsToOptions(ism),
    },
    isLoading || isFetching,
  ];
};

export default useQnousRefOptions;
