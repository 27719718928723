import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
} from '@components/Inputs/Autocomplete';

export type ControlledAutocompleteProps = AutocompleteProps & {
  name: string;
  changeHandler?: (value: any) => void;
  helpMessage?: string;
  defValue?: any;
};

const ControlledAutocomplete: FC<ControlledAutocompleteProps> = ({
  name,
  changeHandler,
  helpMessage,
  defValue,
  ...props
}) => {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, ref, disabled, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            onChange={(_, newValues) => {
              onChange(newValues);
              changeHandler?.(newValues);
              trigger(name);
            }}
            helperText={helpMessage || error?.message}
            error={!!error}
            value={defValue ? value ?? defValue : value}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
