import React from 'react';
import CriteriaBlock from '@components/EntityDrawers/components/CiaInputsGroup/CriteriaBlock';
import useAssuranceChange from '@components/EntityDrawers/components/CiaInputsGroup/hooks/useAssuranceChange';
import useCiaData from '@components/EntityDrawers/components/CiaInputsGroup/hooks/useCiaData';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { LogicalKeys } from '@components/EntityDrawers/drawers/Logical/keys';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import {
  LogicalSubLayerTypes,
  PhysicalSubLayerTypes,
} from '@constants/canvas/layers';
import { Skeleton, Stack } from '@mui/material';

const fieldsNames = {
  [LogicalSubLayerTypes.Services]: LogicalKeys.Policies,
  [PhysicalSubLayerTypes.Capabilities]: PhysicalKeys.Standards,
  [PhysicalSubLayerTypes.Functions]: PhysicalKeys.TechInstructions,
} as const;

const controlsNames = {
  [LogicalSubLayerTypes.Services]: LogicalKeys.Requirement,
  [PhysicalSubLayerTypes.Capabilities]: PhysicalKeys.Control,
  [PhysicalSubLayerTypes.Functions]: PhysicalKeys.Control,
} as const;

const CiaInputsGroup = () => {
  const { isCiaActivated, isLoading, questionnaire } = useCiaData();
  const { subLayer } = useDrawerContext();

  const { handleAssuranceChange } = useAssuranceChange(
    fieldsNames[subLayer as LogicalSubLayerTypes.Services],
    controlsNames[subLayer as LogicalSubLayerTypes.Services],
  );

  if (isLoading) {
    return (
      <Stack gap={4}>
        <Skeleton />
      </Stack>
    );
  }

  if (!isCiaActivated || !questionnaire) return null;

  return (
    <>
      <CriteriaBlock
        title="Assurance"
        fields={questionnaire.cia_criteria.assurance_level.criterias}
        criteriaKey={CommonKeys.Assurance}
        onChange={handleAssuranceChange}
      />

      <CriteriaBlock
        title="Coverage"
        fields={questionnaire.cia_criteria.coverage.criterias}
        criteriaKey={CommonKeys.Coverage}
      />

      <CriteriaBlock
        title="Applicability"
        fields={questionnaire.cia_criteria.applicability.criterias}
        criteriaKey={CommonKeys.Applicability}
      />
    </>
  );
};

export default CiaInputsGroup;
