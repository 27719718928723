import React, { FC } from 'react';
import CiaInputsGroup from '@components/EntityDrawers/components/CiaInputsGroup';
import CurrentMaturity from '@components/EntityDrawers/components/inputs/CurrentMaturity';
import DesignEffectivenessDropdown from '@components/EntityDrawers/components/inputs/DesignEffectivenessDropdown';
import MetricsGroup from '@components/EntityDrawers/components/inputs/MetricsGroup';
import OperationalEffectivenessDropdown from '@components/EntityDrawers/components/inputs/OperationalEffectivenessDropdown';
import OperationalModelDropdown from '@components/EntityDrawers/components/inputs/OperationalModelDropdown';
import OperationalStatusRadioGroup from '@components/EntityDrawers/components/inputs/OperationalStatusRadioGroup';
import TargetMaturity from '@components/EntityDrawers/components/inputs/TargetMaturity';
import TimelineDates from '@components/EntityDrawers/components/inputs/TimelineDates';
import PoliciesGroup from '@components/EntityDrawers/drawers/Logical/inputs/PoliciesGroup';

const Advanced: FC = () => {
  return (
    <>
      <OperationalStatusRadioGroup />
      <TimelineDates />
      <MetricsGroup />
      <CurrentMaturity />
      <TargetMaturity />
      <CiaInputsGroup />
      <PoliciesGroup />
      <DesignEffectivenessDropdown />
      <OperationalEffectivenessDropdown />
      <OperationalModelDropdown />
    </>
  );
};

export default Advanced;
