import React, { FC } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { MAX_VISIBLE_CHIP_SYMBOLS } from '@components/EntityDrawers/components/ReferencesInputsGroup/constants/values';
import useChangeReference from '@components/EntityDrawers/components/ReferencesInputsGroup/hooks/useChangeReference';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import useQnousRefOptions from '@components/EntityDrawers/hooks/useQnousRefOptions';
import ListItemWithInfo from '@components/Inputs/Autocomplete/items/ListItemWithInfo';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { RefType } from '@constants/entities/project';
import { useProject } from '@context/Project';
import useProjectData from '@hooks/useProjectData';
import useReferenceMaps from '@hooks/useReferenceMaps';
import { Chip, Typography } from '@mui/material';

const IsmInput: FC = () => {
  const { viewOnly } = useProject();
  const [{ ismOptions }, isRefsLoading] = useQnousRefOptions();
  const [{ ismMap }] = useReferenceMaps();
  const [{ settingsData }] = useProjectData();

  const { handleChangeReference, isChangingReference } = useChangeReference();

  if (!settingsData?.reference_model.includes(RefType.ISM)) return null;

  return (
    <ControlledAutocomplete
      name={CommonKeys.IsmRef}
      label="ISM reference"
      placeholder="Select reference"
      options={ismOptions}
      loading={isRefsLoading || isChangingReference}
      multiple
      required
      readOnly={viewOnly}
      itemComponent={ListItemWithInfo}
      itemProps={() => ({
        renderTooltipTitle: ({
          label,
          value,
        }: {
          label: string;
          value: string;
        }) => {
          const normalLabel = label || ismMap.get(value);

          const [id, title] = normalLabel?.split(':') ?? ['', ''];

          return (
            <>
              <Typography variant="body1" color="white">
                {id}:
              </Typography>

              <Typography variant="body1" color="grey.500">
                {title}
              </Typography>
            </>
          );
        },
      })}
      onChange={handleChangeReference(CommonKeys.IsmRef)}
      isLoading={isChangingReference || isRefsLoading}
      renderTags={(value, getTagProps) => {
        return value.map(({ value, label }, index) => {
          const normalLabel = label || ismMap.get(value);
          const [id, title] = normalLabel?.split(':') ?? ['', ''];

          return (
            <CustomTooltip
              key={value}
              placement="left"
              disableHoverListener={
                normalLabel?.length < MAX_VISIBLE_CHIP_SYMBOLS
              }
              title={
                <>
                  <Typography variant="body1" color="white">
                    {id}:
                  </Typography>

                  <Typography variant="body1" color="grey.600">
                    {title}
                  </Typography>
                </>
              }
            >
              <Chip
                label={normalLabel}
                size="small"
                variant="grey"
                sx={{ cursor: 'pointer' }}
                {...getTagProps({ index })}
              />
            </CustomTooltip>
          );
        });
      }}
    />
  );
};

export default IsmInput;
