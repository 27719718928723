import React, { FC } from 'react';
import CriteriaInput from '@components/EntityDrawers/components/ControlAssurance/CriteriaInput';
import useSameCheck from '@components/EntityDrawers/components/ControlAssurance/hooks/useSameCheck';
import ControlledCheckbox from '@components/Inputs/controllers/ControlledCheckbox';
import { Stack } from '@mui/material';

type Props = {
  criteriaName: string;
  checkName: string;
};

const ControlAssurance: FC<Props> = ({ criteriaName, checkName }) => {
  const { isSameChecked, handleSameChanged } = useSameCheck(
    checkName,
    criteriaName,
  );

  return (
    <Stack gap={2}>
      <CriteriaInput name={criteriaName} isSame={isSameChecked} />

      <ControlledCheckbox
        label="same as entity Assurance"
        name={checkName}
        onChange={handleSameChanged}
      />
    </Stack>
  );
};

export default ControlAssurance;
